"use client"

import * as React from "react"
import { useForm } from "react-hook-form"
import * as z from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { DBName } from "../../utils/func"
import { useToast } from "../ui/use-toast"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../ui/form"
import { Input } from "../ui/input"
import { Button } from "../ui/button"

const formSchema = z.object({
  name: z.string().min(1, "Name is required"),
})

interface CreateTeamProps {
  showCreate: boolean
  setShowCreate: (show: boolean) => void
  supabase: any
  callback: () => void
  isEdit?: boolean
  payload?: any
}

export function CreateTeam({
  showCreate,
  setShowCreate,
  supabase,
  callback,
  isEdit = false,
  payload,
}: CreateTeamProps) {
  const { toast } = useToast()

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: payload?.name || "",
    },
  })

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      if (isEdit) {
        const { error } = await supabase
          .from(DBName.TEAMS)
          .update(values)
          .eq("id", payload.id)
        
        if (error) throw error
        
        toast({
          title: "Success",
          description: "Team updated successfully",
        })
      } else {
        const id = Math.floor(Math.random() * 5000) + 1000
        const { error } = await supabase
          .from(DBName.TEAMS)
          .insert([{ ...values, id }])
        
        if (error) throw error
        
        toast({
          title: "Success",
          description: "Team created successfully",
        })
      }
      
      callback()
      setShowCreate(false)
    } catch (error: any) {
      toast({
        title: "Error",
        description: error.message || "Something went wrong",
        variant: "destructive",
      })
    }
  }

  const handleDelete = async () => {
    try {
      const { error } = await supabase
        .from(DBName.TEAMS)
        .delete()
        .eq("id", payload.id)
      
      if (error) throw error
      
      toast({
        title: "Success",
        description: "Team deleted successfully",
      })
      
      callback()
      setShowCreate(false)
    } catch (error: any) {
      toast({
        title: "Error",
        description: error.message || "Something went wrong",
        variant: "destructive",
      })
    }
  }

  return (
    <Dialog open={showCreate} onOpenChange={setShowCreate}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{isEdit ? "Edit Team" : "Create Team"}</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Name</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex justify-end gap-2">
              <Button variant="outline" onClick={() => setShowCreate(false)}>
                Cancel
              </Button>
              <Button type="submit">
                {isEdit ? "Update" : "Create"}
              </Button>
            </div>
            {isEdit && (
              <Button 
                type="button"
                variant="outline" 
                className="w-full" 
                onClick={handleDelete}
              >
                Delete
              </Button>
            )}
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
