"use client"

import * as React from "react"
import { useForm } from "react-hook-form"
import * as z from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../ui/form"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select"
import { Input } from "../ui/input"
import { Button } from "../ui/button"
import { DBName } from "../../utils/func"
import { useToast } from "../ui/use-toast"

const formSchema = z.object({
  name: z.string().min(1, "Name is required"),
  team: z.string().min(1, "Team is required"),
})

interface CreateUserProps {
  showCreate: boolean
  setShowCreate: (show: boolean) => void
  supabase: any
  callback: () => void
  isEdit?: boolean
  payload?: any
}

export function CreateUser({
  showCreate,
  setShowCreate,
  supabase,
  callback,
  isEdit = false,
  payload,
}: CreateUserProps) {
  const { toast } = useToast()
  const [teams, setTeams] = React.useState<{ value: string; label: string }[]>([])

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: payload?.name || "",
      team: payload?.team || "",
    },
  })

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      if (isEdit) {
        const { error } = await supabase
          .from(DBName.USERS)
          .update(values)
          .eq("id", payload.id)
        
        if (error) throw error
        
        toast({
          title: "Success",
          description: "User updated successfully",
        })
      } else {
        const { error } = await supabase
          .from(DBName.USERS)
          .insert([values])
        
        if (error) throw error
        
        toast({
          title: "Success",
          description: "User created successfully",
        })
      }
      
      callback()
      setShowCreate(false)
    } catch (error: any) {
      toast({
        title: "Error",
        description: error.message || "Something went wrong",
        variant: "destructive",
      })
    }
  }

  const handleDelete = async () => {
    try {
      const { error } = await supabase
        .from(DBName.USERS)
        .delete()
        .eq("id", payload.id)
      
      if (error) throw error
      
      toast({
        title: "Success",
        description: "User deleted successfully",
      })
      callback()
      setShowCreate(false)
    } catch (error: any) {
      toast({
        title: "Error",
        description: error.message || "Something went wrong",
        variant: "destructive",
      })
    }
  }

  const fetchTeams = async () => {
    try {
      const { data } = await supabase.from(DBName.TEAMS).select("*")
      setTeams(data.map((team: any) => ({
        value: team.id,
        label: team.name,
      })))
    } catch (error) {
      console.error(error)
    }
  }

  React.useEffect(() => {
    fetchTeams()
  }, [])

  return (
    <Dialog open={showCreate} onOpenChange={setShowCreate}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{isEdit ? "Edit User" : "Create User"}</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Name</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="team"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Team</FormLabel>
                  <Select 
                    onValueChange={field.onChange} 
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Select a team" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {teams.map((team) => (
                        <SelectItem key={team.value} value={team.value}>
                          {team.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex justify-end gap-2">
              <Button variant="outline" onClick={() => setShowCreate(false)}>
                Cancel
              </Button>
              <Button type="submit">
                {isEdit ? "Update" : "Create"}
              </Button>
            </div>
            {isEdit && (
              <Button 
                type="button"
                variant="outline" 
                className="w-full" 
                onClick={handleDelete}
              >
                Delete
              </Button>
            )}
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
