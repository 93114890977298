import * as React from "react"
import { Table } from "@tanstack/react-table"
import { format } from "date-fns"
import { DateRange } from "react-day-picker"
import { Button } from "../button"
import { Input } from "../input"
import { X } from "lucide-react"
import { Badge } from "../badge"
import { DatePickerWithRange } from "../date-picker-with-range"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../select"

// Column name mapping for better labels
const COLUMN_LABELS: Record<string, string> = {
  created_at: "Created Date",
  item_name: "Item Name",
  item_type: "Item Type",
  source: "Source",
  type_of_transaction: "Transaction Type",
  dvr_number: "DVR Number",
  dc_number: "DC Number",
  purity: "Purity",
  weight: "Weight",
  quantity: "Quantity",
  packed_or_opened_by_name: "Packed/Opened By",
  processed_by_name: "Processed By",
  type_of_material_item_name: "Material Type",
}

const DATE_COLUMNS = ["created_at"]

interface DataTableCustomFilterProps<TData> {
  table: Table<TData>
}

export function DataTableCustomFilter<TData>({
  table,
}: DataTableCustomFilterProps<TData>) {
  const [searchValue, setSearchValue] = React.useState("")
  const [activeColumn, setActiveColumn] = React.useState<string | null>(null)
  const [filters, setFilters] = React.useState<Array<{
    column: string
    value: any
    label: string
  }>>([])
  const [dateRange, setDateRange] = React.useState<DateRange | undefined>()

  const filterableColumns = table.getAllColumns()
    .filter((column) => column.getCanFilter())
    .map((column) => ({
      value: column.id,
      label: COLUMN_LABELS[column.id] || column.id,
    }))

  const handleDateRangeApply = (range: DateRange) => {
    if (activeColumn && range.from && range.to) {
      const columnLabel = COLUMN_LABELS[activeColumn] || activeColumn
      const newFilter = {
        column: activeColumn,
        value: range,
        label: `${columnLabel}: ${format(range.from, "MMM dd, yyyy")} - ${format(range.to, "MMM dd, yyyy")}`,
      }
      setFilters([...filters, newFilter])
      table.getColumn(activeColumn)?.setFilterValue(range)
      setActiveColumn(null)
    }
  }

  const addFilter = () => {
    if (!activeColumn || !searchValue) return

    const columnLabel = COLUMN_LABELS[activeColumn] || activeColumn
    const newFilter = {
      column: activeColumn,
      value: searchValue,
      label: `${columnLabel}: "${searchValue}"`,
    }

    setFilters([...filters, newFilter])
    table.getColumn(activeColumn)?.setFilterValue(searchValue)
    setSearchValue("")
    setActiveColumn(null)
  }

  const removeFilter = (index: number) => {
    const filter = filters[index]
    table.getColumn(filter.column)?.setFilterValue(undefined)
    if (filter.column === 'created_at') {
      setDateRange(undefined)
    }
    setFilters(filters.filter((_, i) => i !== index))
  }

  return (
    <div className="space-y-4">
      <div className="flex items-center space-x-2">
        <Select value={activeColumn || ""} onValueChange={setActiveColumn}>
          <SelectTrigger className="w-[200px]">
            <SelectValue placeholder="Select column to filter..." />
          </SelectTrigger>
          <SelectContent>
            {filterableColumns.map((column) => (
              <SelectItem key={column.value} value={column.value}>
                {column.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>

        {activeColumn && (
          <>
            {DATE_COLUMNS.includes(activeColumn) ? (
              <DatePickerWithRange
                date={dateRange}
                setDate={setDateRange}
                onApply={handleDateRangeApply}
              />
            ) : (
              <>
                <Input
                  placeholder="Enter filter value..."
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  className="h-8 w-[200px]"
                />
                <Button 
                  variant="outline" 
                  onClick={addFilter}
                  className="h-8"
                >
                  Add Filter
                </Button>
              </>
            )}
          </>
        )}
      </div>

      {filters.length > 0 && (
        <div className="flex flex-wrap gap-2">
          {filters.map((filter, index) => (
            <Badge key={index} variant="secondary" className="text-sm">
              {filter.label}
              <X
                className="ml-2 h-4 w-4 cursor-pointer hover:text-destructive"
                onClick={() => removeFilter(index)}
              />
            </Badge>
          ))}
        </div>
      )}
    </div>
  )
}
