import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { Notifications } from "@mantine/notifications"
import { createClient } from "@supabase/supabase-js"
import { MantineProvider } from "@mantine/core"
//@ts-ignore
const supabase = createClient(`https://${process.env.REACT_APP_PROJECT_ID}.supabase.co`,process.env.REACT_APP_ANON_KEY)

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <React.StrictMode>
    <MantineProvider>
      <Notifications position="top-center" />
      <App supabase={supabase} />
    </MantineProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
