import { useState } from "react"
import { DataTable } from "../components/ui/data-table/data-table"
import { CreateStoreTransaction } from "../components/modals/create-store-transaction"
import Papa from "papaparse"
import { ColumnDef } from "@tanstack/react-table"
import { Button } from "../components/ui/button"
import { ArrowUpDown } from "lucide-react"
import { Checkbox } from "../components/ui/checkbox"
import { DBName } from "../utils/func"

interface StoreTransaction {
  created_at: string
  weight: number
  item_id: string
  from: string
  to: string
  issuer: string
  receiver: string
}

type Props = {
  supabase: any
}

const StoreTransaction = ({ supabase }: Props) => {
  const [showCreate, setShowCreate] = useState(false)

  const columns: ColumnDef<StoreTransaction>[] = [
    {
      id: "select",
      header: ({ table }) => (
        <Checkbox
          checked={table.getIsAllPageRowsSelected()}
          onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
          aria-label="Select all"
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          checked={row.getIsSelected()}
          onCheckedChange={(value) => row.toggleSelected(!!value)}
          aria-label="Select row"
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "created_at",
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Created At
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        )
      },
    },
    {
      accessorKey: "weight",
      header: "Weight",
    },
    {
      accessorKey: "item_id",
      header: "Item",
    },
    {
      accessorKey: "from",
      header: "From",
    },
    {
      accessorKey: "to",
      header: "To",
    },
    {
      accessorKey: "issuer",
      header: "Issuer",
    },
    {
      accessorKey: "receiver",
      header: "Receiver",
    },
  ]

  const fetcherFunction = async ({ pageIndex, pageSize, filters, sort }: any) => {
    try {
      let query = supabase
        .from('store_transactions')
        .select(`
          created_at,
          weight,
          item_id: Items (item_name),
          from:teams!store_transactions_from_fkey (name),
          to:teams!store_transactions_to_fkey (name),
          issuer:users!store_transactions_issuer_fkey (name),
          receiver:users!store_transactions_receiver_fkey (name)
        `, { count: "exact" })
        .range(pageIndex * pageSize, (pageIndex + 1) * pageSize - 1)

      if (filters && filters.length > 0) {
        filters.forEach((filter: { id: any; value: any }) => {
          query = query.ilike(filter.id, `%${filter.value}%`)
        })
      }

      if (sort && sort.length > 0) {
        sort.forEach((sortObj: any) => {
          query = query.order(sortObj.id, { ascending: !sortObj.desc })
        })
      }

      const { data, count } = await query
      
      const formattedData = data?.map((d: any) => ({
        ...d,
        created_at: new Date(d.created_at).toLocaleString(),
        from: d.from?.name || "",
        to: d.to?.name || "",
        issuer: d.issuer?.name || "",
        receiver: d.receiver?.name || "",
        item_id: d.item_id?.item_name || "",
      })) || []

      return { data: formattedData, count: count || 0 }
    } catch (error) {
      console.error("Error fetching data:", error)
      return { data: [], count: 0 }
    }
  }

  const downloadCSV = async (table: any) => {
    try {
      const { data } = await supabase.from(DBName.STORE_TRANSACTIONS).select("*")
      const csv = Papa.unparse(data)
      const blob = new Blob([csv], { type: "text/csv" })
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement("a")
      a.href = url
      a.download = `StoreTransactions_${new Date().toISOString()}.csv`
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      window.URL.revokeObjectURL(url)
    } catch (error) {
      console.error("Error downloading CSV:", error)
    }
  }

  return (
    <div className="container mx-auto py-10">
      <DataTable<StoreTransaction>
        columns={columns}
        fetcher={fetcherFunction}
        onCreate={() => setShowCreate(true)}
        handleDownload={downloadCSV}
        CreateComponent={CreateStoreTransaction}
        showCreate={showCreate}
        setShowCreate={setShowCreate}
        supabase={supabase}
      />
    </div>
  )
}

export default StoreTransaction
