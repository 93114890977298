import { useState } from "react"
import { DataTable } from "../components/ui/data-table/data-table"
import { ColumnDef } from "@tanstack/react-table"
import { format, isValid, parse } from "date-fns"
import { Button } from "../components/ui/button"
import { DBName } from "../utils/func"
import Papa from "papaparse"
import { Checkbox } from "../components/ui/checkbox"
import { ArrowUpDown } from "lucide-react"
import CreateVendorOutwork from "../components/modals/create-vendor-outwork"

interface VendorOutwork {
  "Order Number": string
  "Target Date": string
  "Line No": string
  "Order Type": string
  "Batch Number": string
  "Product": string
  "Description": string
  "UOM1": string
  "UOM2": string
  "Qty": number
  "Pure Wt": number
  "BOM Dia Qty": number
  "BOM Clr Qty": number
  "BOM Tot Qty": number
  "Issue Tot Qty": number
  "Stn Wt/Gms": number
  "Value": number
  "Warehouse": string
  "Vendor Short Name": string
  "Vendor Site": string
  "JO No": string
  "JO Date": string
  "JO Status": string
  "Inw No": string
  "Inward Date": string
  "Inward Status": string
  "Transfer No": string
  "Material Posted": string
  "Standard Weight": number
  "Prod Route": string
  "Card Release": string
  "Booked Date": string
  "Karigar Name": string
  "Loaded Date": string
  "Received Date": string
  "Status": string
}

const formatDateString = (dateStr: string | null) => {
  if (!dateStr) return ""
  try {
    const formats = ["yyyy-MM-dd", "dd-MMM-yy", "d-MMM-yy"]
    for (const dateFormat of formats) {
      const parsedDate = parse(dateStr, dateFormat, new Date())
      if (isValid(parsedDate)) {
        return format(parsedDate, "dd-MMM-yy")
      }
    }
    return dateStr
  } catch {
    return dateStr
  }
}

const columns: ColumnDef<VendorOutwork>[] = [
  {
    id: "select",
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllPageRowsSelected()}
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "Order Number",
    header: ({ column }) => (
      <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
        Order Number
        <ArrowUpDown className="ml-2 h-4 w-4" />
      </Button>
    ),
    enableColumnFilter: true,
  },
  {
    accessorKey: "Target Date",
    header: ({ column }) => (
      <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
        Target Date
        <ArrowUpDown className="ml-2 h-4 w-4" />
      </Button>
    ),
    cell: ({ row }) => formatDateString(row.getValue("Target Date")),
    enableColumnFilter: true,
  },
  {
    accessorKey: "Line No",
    header: "Line No",
    enableColumnFilter: true,
  },
  {
    accessorKey: "Order Type",
    header: "Order Type",
    enableColumnFilter: true,
  },
  {
    accessorKey: "Batch Number",
    header: "Batch Number",
    enableColumnFilter: true,
  },
  {
    accessorKey: "Product",
    header: "Product",
    enableColumnFilter: true,
  },
  {
    accessorKey: "Description",
    header: "Description",
    enableColumnFilter: true,
  },
  {
    accessorKey: "UOM1",
    header: "UOM1",
  },
  {
    accessorKey: "UOM2",
    header: "UOM2",
  },
  {
    accessorKey: "Qty",
    header: "Qty",
  },
  {
    accessorKey: "Pure Wt",
    header: "Pure Wt",
  },
  {
    accessorKey: "BOM Dia Qty",
    header: "BOM Dia Qty",
  },
  {
    accessorKey: "BOM Clr Qty",
    header: "BOM Clr Qty",
  },
  {
    accessorKey: "BOM Tot Qty",
    header: "BOM Tot Qty",
  },
  {
    accessorKey: "Issue Tot Qty",
    header: "Issue Tot Qty",
  },
  {
    accessorKey: "Stn Wt/Gms",
    header: "Stn Wt/Gms",
  },
  {
    accessorKey: "Value",
    header: "Value",
  },
  {
    accessorKey: "Warehouse",
    header: "Warehouse",
    enableColumnFilter: true,
  },
  {
    accessorKey: "Vendor Short Name",
    header: "Vendor Short Name",
    enableColumnFilter: true,
  },
  {
    accessorKey: "Vendor Site",
    header: "Vendor Site",
    enableColumnFilter: true,
  },
  {
    accessorKey: "JO No",
    header: "JO No",
    enableColumnFilter: true,
  },
  {
    accessorKey: "JO Date",
    header: ({ column }) => (
      <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
        JO Date
        <ArrowUpDown className="ml-2 h-4 w-4" />
      </Button>
    ),
    cell: ({ row }) => formatDateString(row.getValue("JO Date")),
    enableColumnFilter: true,
  },
  {
    accessorKey: "JO Status",
    header: "JO Status",
    enableColumnFilter: true,
  },
  {
    accessorKey: "Inw No",
    header: "Inw No",
    enableColumnFilter: true,
  },
  {
    accessorKey: "Inward Date",
    header: ({ column }) => (
      <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
        Inward Date
        <ArrowUpDown className="ml-2 h-4 w-4" />
      </Button>
    ),
    cell: ({ row }) => formatDateString(row.getValue("Inward Date")),
    enableColumnFilter: true,
  },
  {
    accessorKey: "Inward Status",
    header: "Inward Status",
    enableColumnFilter: true,
  },
  {
    accessorKey: "Transfer No",
    header: "Transfer No",
    enableColumnFilter: true,
  },
  {
    accessorKey: "Material Posted",
    header: "Material Posted",
    enableColumnFilter: true,
  },
  {
    accessorKey: "Standard Weight",
    header: "Standard Weight",
  },
  {
    accessorKey: "Prod Route",
    header: "Prod Route",
    enableColumnFilter: true,
  },
  {
    accessorKey: "Card Release",
    header: "Card Release",
    enableColumnFilter: true,
  },
  {
    accessorKey: "Booked Date",
    header: ({ column }) => (
      <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
        Booked Date
        <ArrowUpDown className="ml-2 h-4 w-4" />
      </Button>
    ),
    cell: ({ row }) => formatDateString(row.getValue("Booked Date")),
    enableColumnFilter: true,
  },
  {
    accessorKey: "Karigar Name",
    header: "Karigar Name",
    enableColumnFilter: true,
  },
  {
    accessorKey: "Loaded Date",
    header: ({ column }) => (
      <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
        Loaded Date
        <ArrowUpDown className="ml-2 h-4 w-4" />
      </Button>
    ),
    cell: ({ row }) => formatDateString(row.getValue("Loaded Date")),
    enableColumnFilter: true,
  },
  {
    accessorKey: "Received Date",
    header: ({ column }) => (
      <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
        Received Date
        <ArrowUpDown className="ml-2 h-4 w-4" />
      </Button>
    ),
    cell: ({ row }) => formatDateString(row.getValue("Received Date")),
    enableColumnFilter: true,
  },
  {
    accessorKey: "Status",
    header: "Status",
    enableColumnFilter: true,
  }
]

const VendorOutwork = ({ supabase }: { supabase: any }) => {
  const [showCreate, setShowCreate] = useState(false)
  // const [selectedRow, setSelectedRow] = useState<VendorOutwork | null>(null)

  // const handleRowClick = (row: VendorOutwork) => {
  //   setSelectedRow(row)
  //   setShowCreate(true)
  // }

  const fetcher = async ({ pageIndex, pageSize, filters, sort }: any) => {
    try {
      let query = supabase
        .from(DBName.VENDOR_OUTWORK)
        .select("*", { count: "exact" })
        .range(pageIndex * pageSize, (pageIndex + 1) * pageSize - 1)

      if (filters?.length) {
        filters.forEach((filter: any) => {
          if (!filter.id || !filter.value) return
          query = query.ilike(filter.id, `%${filter.value}%`)
        })
      }

      if (sort?.length) {
        sort.forEach((s: any) => {
          query = query.order(s.id, { ascending: !s.desc })
        })
      }

      const { data, error, count } = await query
      if (error) throw error

      const formattedData = data?.map((d: any) => ({
        ...d,
        created_at: new Date(d.created_at).toLocaleString(),
      })) || []

      return { 
        data: formattedData, 
        count: count || 0 
      }
    } catch (error) {
      console.error("Error fetching data:", error)
      return { data: [], count: 0 }
    }
  }

  const downloadCSV = async (table: any) => {
    try {
      let query = supabase.from(DBName.VENDOR_OUTWORK).select("*")
      
      // Apply current filters to download
      const currentFilters = table.getState().columnFilters
      const currentSort = table.getState().sorting

      if (currentFilters.length > 0) {
        currentFilters.forEach((filter: any) => {
          if (filter.value) {
            query = query.ilike(filter.id, `%${filter.value}%`)
          }
        })
      }

      if (currentSort.length > 0) {
        currentSort.forEach((sort: any) => {
          query = query.order(sort.id, { ascending: !sort.desc })
        })
      }

      const { data, error } = await query
      if (error) throw error

      const formattedData = data.map((item: any) => ({
        ...item,
        created_at: new Date(item.created_at).toLocaleString()
      }))

      const csv = Papa.unparse(formattedData)
      const blob = new Blob([csv], { type: "text/csv" })
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement("a")
      a.href = url
      a.download = `VendorOutwork_${new Date().toISOString()}.csv`
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      window.URL.revokeObjectURL(url)
    } catch (error) {
      console.error("Error downloading CSV:", error)
    }
  }

  return (
    <div className="container mx-auto py-10">
      <div className="relative overflow-hidden">
        <DataTable
          columns={columns}
          fetcher={fetcher}
          onCreate={() => setShowCreate(true)}
          handleDownload={downloadCSV}
          CreateComponent={CreateVendorOutwork}
          showCreate={showCreate}
          setShowCreate={setShowCreate}
          supabase={supabase}
        />
      </div>
    </div>
  )
}

export default VendorOutwork