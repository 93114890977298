import { useState, useEffect } from "react"
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../ui/dialog"
import { Button } from "../ui/button"
import { Input } from "../ui/input"
import Papa from "papaparse"
import { showNotification, hideNotification } from "@mantine/notifications"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../ui/table"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select"
import { ScrollArea } from "../ui/scroll-area"
import { Command, CommandInput, CommandEmpty, CommandGroup, CommandItem } from "../ui/command"
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover"
import { isValid } from "date-fns"
import { Search, X } from "lucide-react"

interface Props {
  supabase: any
  showCreate: boolean
  setShowCreate: (show: boolean) => void
  callback: () => void
}

const REQUIRED_COLUMNS = [
  "Order Number", "Target Date", "Line No", "Order Type", "Batch Number",
  "Product", "Description", "UOM1", "UOM2", "Qty", "Pure Wt",
  "BOM Dia Qty", "BOM Clr Qty", "BOM Tot Qty", "Issue Tot Qty",
  "Stn Wt/Gms", "Value", "Warehouse", "Vendor Short Name", "Vendor Site",
  "JO No", "JO Date", "JO Status", "Inw No", "Inward Date", "Inward Status",
  "Transfer No", "Material Posted", "Standard Weight", "Prod Route",
  "Card Release", "Booked Date", "DTRJ_JO", "REJN_JO", "Karigar Name",
  "Loaded Date", "Received Date", "OL Num", "OL Creation Date",
  "TOC Order Type", "Vendor_Lt", "SCM_Lt", "TOC Due Date", "DDP Date",
  "TOC Reason", "Filler Type", "Offline Creation Date", "Complexity",
  "Vendor Rcvd Date", "JO Received Date", "MTR Color", "Skill Set Name",
  "Skill Set Desc", "TOC Color", "Planning Reference", "Planning Remarks",
  "HM Product Category", "HM Brand", "HM Purity", "HM Piece Count",
  "Transfer Date", "Waybill Date", "Wabill No", "Remarks", "Status",
  "Group Id", "QC Pass", "QC Fail Reason", "QC Remarks", "Store Transaction Ids"
]

const cleanData = (row: Record<string, any>) => {
  // Helper function to handle numeric fields
  const parseNumeric = (value: any) => {
    if (value === '' || value === null || value === undefined) return null;
    const parsed = Number(value);
    return isNaN(parsed) ? null : parsed;
  };

  // Helper function to handle text fields
  const parseText = (value: any) => {
    return value || null;
  };

  // Helper function to handle boolean fields
  const parseBoolean = (value: any) => {
    if (value === '' || value === null || value === undefined) return null;
    return value === 'true' || value === true;
  };

  return {
    // Text fields
    'Order Number': parseText(row['Order Number']),
    'Target Date': parseText(row['Target Date']),
    'Order Type': parseText(row['Order Type']),
    'Product': parseText(row['Product']),
    'Description': parseText(row['Description']),
    'UOM1': parseText(row['UOM1']),
    'UOM2': parseText(row['UOM2']),
    'Pure Wt': parseText(row['Pure Wt']),
    'BOM Dia Qty': parseText(row['BOM Dia Qty']),
    'BOM Clr Qty': parseText(row['BOM Clr Qty']),
    'BOM Tot Qty': parseText(row['BOM Tot Qty']),
    'Issue Tot Qty': parseText(row['Issue Tot Qty']),
    'Stn Wt/Gms': parseText(row['Stn Wt/Gms']),
    'Value': parseText(row['Value']),
    'Warehouse': parseText(row['Warehouse']),
    'Vendor Short Name': parseText(row['Vendor Short Name']),
    'Vendor Site': parseText(row['Vendor Site']),
    'JO No': parseText(row['JO No']),
    'JO Date': parseText(row['JO Date']),
    'JO Status': parseText(row['JO Status']),
    'Inw No': parseText(row['Inw No']),
    'Inward Date': parseText(row['Inward Date']),
    'Inward Status': parseText(row['Inward Status']),
    'Transfer No': parseText(row['Transfer No']),
    'Material Posted': parseText(row['Material Posted']),
    'Prod Route': parseText(row['Prod Route']),
    'Card Release': parseText(row['Card Release']),
    'Booked Date': parseText(row['Booked Date']),
    'DTRJ_JO': parseText(row['DTRJ_JO']),
    'REJN_JO': parseText(row['REJN_JO']),
    'Karigar Name': parseText(row['Karigar Name']),
    'Loaded Date': parseText(row['Loaded Date']),
    'Received Date': parseText(row['Received Date']),
    'OL Creation Date': parseText(row['OL Creation Date']),
    'TOC Order Type': parseText(row['TOC Order Type']),
    'Vendor_Lt': parseText(row['Vendor_Lt']),
    'TOC Due Date': parseText(row['TOC Due Date']),
    'DDP Date': parseText(row['DDP Date']),
    'TOC Reason': parseText(row['TOC Reason']),
    'Filler Type': parseText(row['Filler Type']),
    'Offline Creation Date': parseText(row['Offline Creation Date']),
    'Complexity': parseText(row['Complexity']),
    'Vendor Rcvd Date': parseText(row['Vendor Rcvd Date']),
    'JO Received Date': parseText(row['JO Received Date']),
    'MTR Color': parseText(row['MTR Color']),
    'Skill Set Name': parseText(row['Skill Set Name']),
    'Skill Set Desc': parseText(row['Skill Set Desc']),
    'TOC Color': parseText(row['TOC Color']),
    'Planning Reference': parseText(row['Planning Reference']),
    'Planning Remarks': parseText(row['Planning Remarks']),
    'HM Product Category': parseText(row['HM Product Category']),
    'HM Brand': parseText(row['HM Brand']),
    'Transfer Date': parseText(row['Transfer Date']),
    'Waybill Date': parseText(row['Waybill Date']),
    'Wabill No': parseText(row['Wabill No']),
    'Remarks': parseText(row['Remarks']),
    'Status': parseText(row['Status']),
    'Group Id': parseText(row['Group Id']),
    'QC Remarks': parseText(row['QC Remarks']),

    // Numeric fields (bigint)
    'Batch Number': parseNumeric(row['Batch Number']),
    'Qty': parseNumeric(row['Qty']),
    'OL Num': parseNumeric(row['OL Num']),
    'SCM_Lt': parseNumeric(row['SCM_Lt']),
    'HM Piece Count': parseNumeric(row['HM Piece Count']),

    // Numeric fields (double precision)
    'Line No': parseNumeric(row['Line No']),
    'Standard Weight': parseNumeric(row['Standard Weight']),
    'HM Purity': parseNumeric(row['HM Purity']),

    // Boolean fields
    'QC Pass': parseBoolean(row['QC Pass']),

    // Special fields
    'QC Fail Reason': parseText(row['QC Fail Reason']), // Enum type
    'Store Transaction Ids': row['Store Transaction Ids'] ? 
      Array.isArray(row['Store Transaction Ids']) ? 
        row['Store Transaction Ids'] : 
        [parseNumeric(row['Store Transaction Ids'])] 
      : null, // bigint[]
  };
};

const CreateVendorOutwork = ({ supabase, showCreate, setShowCreate, callback }: Props) => {
  const [file, setFile] = useState<File | null>(null)
  const [previewData, setPreviewData] = useState<any[]>([])
  const [headers, setHeaders] = useState<string[]>([])
  const [columnMapping, setColumnMapping] = useState<{[key: string]: string}>({})
  const [fileName, setFileName] = useState("")
  const [searchValue, setSearchValue] = useState("")
  const [open, setOpen] = useState(false)
  const [step, setStep] = useState<'upload' | 'mapping'>('upload')
  const [parsedData, setParsedData] = useState<any[]>([]);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [loadingNotificationId, setLoadingNotificationId] = useState<string | null>(null);
  const [openDropdown, setOpenDropdown] = useState<string | null>(null)
  const [searchQuery, setSearchQuery] = useState("")

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      setFile(file)
      setFileName(file.name)
      Papa.parse(file, {
        header: true,
        complete: (results) => {
          const csvHeaders = Object.keys(results.data[0] as object)
          setHeaders(csvHeaders)
          
          // Auto-map columns based on similarity
          const initialMapping: {[key: string]: string} = {}
          REQUIRED_COLUMNS.forEach(required => {
            const match = csvHeaders.find(header => 
              header.toLowerCase().replace(/[^a-z0-9]/g, '') === 
              required.toLowerCase().replace(/[^a-z0-9]/g, '')
            )
            if (match) {
              initialMapping[required] = match
            }
          })
          setColumnMapping(initialMapping)
          setPreviewData(results.data.slice(0, 100))
          setParsedData(results.data);
          setTotalRows(results.data.length);
        }
      })
    }
  }

  const handleColumnMap = (requiredColumn: string, csvColumn: string) => {
    setColumnMapping(prev => ({
      ...prev,
      [requiredColumn]: csvColumn
    }))
  }

  const handleImport = async () => {
    const loadingNotificationId = 'import-loading';  // Create a fixed ID

    try {
      // Filter out rows with invalid Batch Numbers before processing
      const validData = parsedData.filter(row => {
        const batchNumber = row['Batch Number'];
        return batchNumber !== null && batchNumber !== undefined && batchNumber !== '';
      });

      if (validData.length === 0) {
        throw new Error('No valid rows found - all rows must have a Batch Number');
      }

      // Transform the data
      const cleanedData = validData.map((row, index) => {
        const mappedRow: Record<string, any> = {};
        
        Object.entries(columnMapping).forEach(([requiredColumn, csvColumn]) => {
          if (csvColumn) {
            mappedRow[requiredColumn] = row[csvColumn];
          }
        });
        
        const cleaned = cleanData(mappedRow);
        
        // Validate Batch Number specifically
        if (!cleaned['Batch Number']) {
          throw new Error(`Row ${index + 1}: Missing required Batch Number`);
        }
        
        return cleaned;
      });

      // Show loading notification with our custom ID
      showNotification({
        id: loadingNotificationId,  // Use our custom ID
        title: 'Importing Data',
        message: `Importing ${cleanedData.length} rows...`,
        loading: true,
        autoClose: false,
      });

      const { error } = await supabase
        .from('vendor_outwork')
        .upsert(cleanedData, {
          onConflict: 'Batch Number'
        });

      if (error) throw error;
      
      // Hide the loading notification
      hideNotification(loadingNotificationId);
      
      // Show success notification
      showNotification({
        title: 'Success',
        message: `Successfully imported ${cleanedData.length} rows`,
        color: 'green',
      });
      
      callback();
      setShowCreate(false);
    } catch (error) {
      console.error('Error importing data:', error);
      // Hide loading notification if there's an error
      hideNotification(loadingNotificationId);
      
      showNotification({
        title: 'Error',
        message: (error as Error).message || 'Failed to import data',
        color: 'red',
      });
    }
  };

  const renderColumnMapping = (requiredColumn: string) => (
    <div className="space-y-2">
      <Select
        value={columnMapping[requiredColumn] || ""}
        onValueChange={(value) => {
          setColumnMapping(prev => ({
            ...prev,
            [requiredColumn]: value
          }))
        }}
      >
        <SelectTrigger className="w-full">
          <div className="flex justify-between items-center flex-1">
            <SelectValue placeholder="Select column" />
            {columnMapping[requiredColumn] && (
              <Button
                type="button"
                variant="ghost"
                size="sm"
                className="h-4 w-4 p-0 hover:bg-transparent"
                onClick={(e) => {
                  e.stopPropagation()
                  setColumnMapping(prev => {
                    const newMapping = { ...prev }
                    delete newMapping[requiredColumn]
                    return newMapping
                  })
                }}
              >
                <X className="h-4 w-4" />
              </Button>
            )}
          </div>
        </SelectTrigger>
        <SelectContent className="h-[200px]">
          <div className="p-2 border-b">
            <div className="flex items-center gap-2 px-2 py-1 border rounded-md">
              <Search className="w-4 h-4 text-muted-foreground" />
              <input
                className="flex-1 bg-transparent outline-none"
                placeholder="Search columns..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
          </div>
          <ScrollArea className="h-[145px]">
            {headers
              .filter(header => 
                header.toLowerCase().includes(searchQuery.toLowerCase())
              )
              .map(header => (
                <SelectItem 
                  key={header} 
                  value={header}
                  className="cursor-pointer"
                >
                  {header}
                </SelectItem>
              ))}
          </ScrollArea>
        </SelectContent>
      </Select>
    </div>
  )

  return (
    <Dialog open={showCreate} onOpenChange={setShowCreate}>
      <DialogContent className="max-w-[90vw] max-h-[90vh] flex flex-col">
        <DialogHeader>
          <DialogTitle>Import Vendor Outwork Data</DialogTitle>
        </DialogHeader>
        
        {step === 'upload' ? (
          // File Upload Step
          <div className="flex flex-col items-center justify-center py-12 space-y-4">
            <div className="border-2 border-dashed border-gray-300 rounded-lg p-12 text-center hover:border-gray-400 transition-colors">
              <input
                type="file"
                accept=".csv"
                onChange={handleFileUpload}
                className="hidden"
                id="csv-upload"
              />
              <label 
                htmlFor="csv-upload" 
                className="cursor-pointer flex flex-col items-center space-y-2"
              >
                <div className="rounded-full bg-primary/10 p-3">
                  <svg
                    className="h-6 w-6 text-primary"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                    />
                  </svg>
                </div>
                <div className="text-sm text-muted-foreground">
                  <span className="font-semibold text-primary">Click to upload</span> or drag and drop
                </div>
                <div className="text-xs text-muted-foreground">
                  CSV files only
                </div>
              </label>
            </div>
            {fileName && (
              <div className="flex items-center space-x-2">
                <span className="text-sm text-muted-foreground">{fileName}</span>
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => {
                    setFile(null)
                    setFileName('')
                    setHeaders([])
                    setPreviewData([])
                    setColumnMapping({})
                  }}
                >
                  <svg
                    className="h-4 w-4"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </Button>
              </div>
            )}
            <Button 
              onClick={() => setStep('mapping')} 
              disabled={!file}
              className="mt-4"
            >
              Continue
            </Button>
          </div>
        ) : (
          // Column Mapping Step (existing code)
          <div className="grid grid-cols-[300px,1fr] gap-4 flex-1 overflow-hidden">
            {/* Existing column mapping code */}
            <div className="space-y-4 overflow-y-auto pr-4">
              <div className="flex justify-between items-center">
                <h3 className="font-medium">Column Mapping</h3>
                <Button 
                  variant="ghost" 
                  size="sm"
                  onClick={() => setStep('upload')}
                >
                  Change File
                </Button>
              </div>
              {REQUIRED_COLUMNS.map(column => (
                <div key={column} className="space-y-2">
                  <label className="text-sm">{column}</label>
                  {renderColumnMapping(column)}
                </div>
              ))}
            </div>

            {/* Existing preview table code */}
            <div className="overflow-hidden flex flex-col">
              <h3 className="font-medium mb-2">
                Preview (showing 100/{totalRows} rows)
              </h3>
              <div className="border rounded-md overflow-auto">
                <Table>
                  <TableHeader>
                    <TableRow>
                      {headers.map(header => (
                        <TableHead key={header}>{header}</TableHead>
                      ))}
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {previewData.slice(0, 100).map((row, i) => (
                      <TableRow key={i}>
                        {headers.map(header => (
                          <TableCell key={header} className="whitespace-nowrap">
                            {row[header]}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                    {previewData.length > 100 && (
                      <TableRow>
                        <TableCell colSpan={headers.length} className="text-center text-muted-foreground">
                          + {previewData.length - 100} more rows
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </div>
            </div>
          </div>
        )}

        <div className="flex justify-end space-x-2 mt-4">
          <Button variant="outline" onClick={() => setShowCreate(false)}>
            Cancel
          </Button>
          {step === 'mapping' && (
            <Button 
              onClick={handleImport} 
              disabled={!parsedData.length || Object.keys(columnMapping).length === 0}
            >
              Import {totalRows} Rows
            </Button>
          )}
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default CreateVendorOutwork
