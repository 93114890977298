import { useState } from "react"
import { DataTable } from "../components/ui/data-table/data-table"
import { CreateItem } from "../components/modals/create-item"
import Papa from "papaparse"
import { ColumnDef } from "@tanstack/react-table"
import { Button } from "../components/ui/button"
import { ArrowUpDown } from "lucide-react"
import { Checkbox } from "../components/ui/checkbox"

type Props = {
  supabase: any
}

interface Item {
  id: string
  created_at: string
  item_name: string
  item_type: string
}

const Items = ({ supabase }: Props) => {
  const [showCreate, setShowCreate] = useState(false)

  // Define columns with sorting and filtering
  const columns: ColumnDef<Item>[] = [
    {
      id: "select",
      header: ({ table }) => (
        <Checkbox
          checked={table.getIsAllPageRowsSelected()}
          onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
          aria-label="Select all"
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          checked={row.getIsSelected()}
          onCheckedChange={(value) => row.toggleSelected(!!value)}
          aria-label="Select row"
        />
      ),
      enableSorting: false,
      enableHiding: false,
      size: 40,
    },
    {
      accessorKey: "id",
      header: "ID",
      enableSorting: true,
      enableHiding: false,
      enableColumnFilter: true,
      size: 100,
    },
    {
      accessorKey: "created_at",
      header: ({ column }) => {
        return (
          <Button
            variant="outline"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Created At
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        )
      },
      enableColumnFilter: true,
      size: 200,
    },
    {
      accessorKey: "item_name",
      header: "Item Name",
      enableSorting: true,
      enableColumnFilter: true,
      size: 300,
    },
    {
      accessorKey: "item_type",
      header: "Item Type",
      enableSorting: true,
      enableColumnFilter: true,
      size: 200,
    },
  ]

  const fetcherFunction = async ({ pageIndex, pageSize, filters, sort }: any) => {
    try {
      let query = supabase
        .from("Items")
        .select("*", { count: "exact" })
        .order("created_at", { ascending: false })
        .range(pageIndex * pageSize, (pageIndex + 1) * pageSize - 1)

      // Handle filters
      if (filters && Array.isArray(filters) && filters.length > 0) {
        filters.forEach((filter: { id: any; value: any }) => {
          if (filter.value) {
            if (filter.id === "id") {
              query = query.eq(filter.id, filter.value)
            } else if (filter.id === "created_at") {
              if (filter.value?.from) {
                query = query.gte(filter.id, filter.value.from)
              }
              if (filter.value?.to) {
                query = query.lte(filter.id, filter.value.to)
              }
            } else if (filter.id === "global") {
              // Handle global search across multiple columns
              query = query.or(
                `item_name.ilike.%${filter.value}%,item_type.ilike.%${filter.value}%`
              )
            } else if (filter.id === "item_type") {
              // Handle multi-select filter for item_type
              if (Array.isArray(filter.value)) {
                query = query.in(filter.id, filter.value)
              } else {
                query = query.ilike(filter.id, `%${filter.value}%`)
              }
            } else {
              query = query.ilike(filter.id, `%${filter.value}%`)
            }
          }
        })
      }

      // Handle sorting
      if (sort && Array.isArray(sort) && sort.length > 0) {
        sort.forEach((sortObj: any) => {
          query = query.order(sortObj.id, { ascending: !sortObj.desc })
        })
      }

      const { data, count, error } = await query

      if (error) throw error

      if (data) {
        data.forEach((d: any) => {
          const date = new Date(d.created_at)
          d.created_at = date.toLocaleString()
        })
      }

      return { data: data || [], count: count || 0 }
    } catch (error) {
      console.error(error)
      return { data: [], count: 0 }
    }
  }

  const downloadCSV = async (table: any) => {
    try {
      let query = supabase.from("Items").select("*")
      
      // Get current filters from the table
      const currentFilters = table.getState().columnFilters
      const currentSort = table.getState().sorting
      
      // Apply filters if they exist
      if (currentFilters.length > 0) {
        currentFilters.forEach((filter: any) => {
          if (filter.value) {
            if (filter.id === "id") {
              query = query.eq(filter.id, filter.value)
            } else if (filter.id === "created_at") {
              if (filter.value?.from) {
                query = query.gte(filter.id, filter.value.from)
              }
              if (filter.value?.to) {
                query = query.lte(filter.id, filter.value.to)
              }
            } else if (filter.id === "item_type" && Array.isArray(filter.value)) {
              query = query.in(filter.id, filter.value)
            } else {
              query = query.ilike(filter.id, `%${filter.value}%`)
            }
          }
        })
      }

      // Apply sorting if it exists
      if (currentSort.length > 0) {
        currentSort.forEach((sort: any) => {
          query = query.order(sort.id, { ascending: !sort.desc })
        })
      }

      const { data, error } = await query
      
      if (error) throw error

      // Format dates
      const formattedData = data.map((item: any) => ({
        ...item,
        created_at: new Date(item.created_at).toLocaleString()
      }))

      const csv = Papa.unparse(formattedData)
      const blob = new Blob([csv], { type: "text/csv" })
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement("a")
      a.href = url
      a.download = `Items_${new Date().toISOString()}.csv`
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      window.URL.revokeObjectURL(url)
    } catch (error) {
      console.error("Error downloading CSV:", error)
      // Add toast notification here if you want to show error to user
    }
  }

  return (
    <div className="container mx-auto py-10">
      <DataTable<Item>
        columns={columns}
        fetcher={fetcherFunction}
        onCreate={() => setShowCreate(true)}
        handleDownload={downloadCSV}
        CreateComponent={CreateItem}
        showCreate={showCreate}
        setShowCreate={setShowCreate}
        supabase={supabase}
      />
    </div>
  )
}

export default Items
