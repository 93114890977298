import { useState } from "react"
import { DataTable } from "../components/ui/data-table/data-table"
import { CreateTeam } from "../components/modals/create-team"
import Papa from "papaparse"
import { ColumnDef } from "@tanstack/react-table"
import { Button } from "../components/ui/button"
import { ArrowUpDown } from "lucide-react"
import { Checkbox } from "../components/ui/checkbox"
import { DBName } from "../utils/func"

interface Team {
  id: string
  created_at: string
  name: string
}

type Props = {
  supabase: any
}

const Teams = ({ supabase }: Props) => {
  const [showCreate, setShowCreate] = useState(false)
  const [selectedPayload, setSelectedPayload] = useState<Team | null>(null)

  const columns: ColumnDef<Team>[] = [
    {
      id: "select",
      header: ({ table }) => (
        <Checkbox
          checked={table.getIsAllPageRowsSelected()}
          onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
          aria-label="Select all"
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          checked={row.getIsSelected()}
          onCheckedChange={(value) => row.toggleSelected(!!value)}
          aria-label="Select row"
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "id",
      header: "ID",
      enableSorting: true,
      enableHiding: false,
    },
    {
      accessorKey: "created_at",
      header: ({ column }) => (
        <Button
          variant="outline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Created At
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      ),
      enableColumnFilter: true,
    },
    {
      accessorKey: "name",
      header: ({ column }) => (
        <Button
          variant="outline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Name
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      ),
      enableColumnFilter: true,
    }
  ]

  const fetcherFunction = async ({ pageIndex, pageSize, filters, sort }: any) => {
    try {
      let query = supabase
        .from(DBName.TEAMS)
        .select("*", { count: "exact" })
        .order("created_at", { ascending: false })
        .range(pageIndex * pageSize, (pageIndex + 1) * pageSize - 1)

      if (filters && filters.length > 0) {
        filters.forEach((filter: { id: any; value: any }) => {
          if (!filter.id || !filter.value) return
          query = query.ilike(filter.id, `%${filter.value}%`)
        })
      }

      if (sort && sort.length > 0) {
        sort.forEach((sortObj: any) => {
          query = query.order(sortObj.id, { ascending: !sortObj.desc })
        })
      }

      const { data, count } = await query
      
      if (!data) return { data: [], count: 0 }

      const formattedData = data.map((d: any) => ({
        ...d,
        created_at: new Date(d.created_at).toLocaleString()
      }))
      
      return { data: formattedData, count: count || 0 }
    } catch (error) {
      console.error("Error fetching data:", error)
      return { data: [], count: 0 }
    }
  }

  const downloadCSV = async (table: any) => {
    try {
      const { data } = await supabase.from(DBName.TEAMS).select("*")
      const formattedData = data.map((item: any) => ({
        ...item,
        created_at: new Date(item.created_at).toLocaleString()
      }))
      const csv = Papa.unparse(formattedData)
      const blob = new Blob([csv], { type: "text/csv" })
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement("a")
      a.href = url
      a.download = `Teams_${new Date().toISOString()}.csv`
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      window.URL.revokeObjectURL(url)
    } catch (error) {
      console.error("Error downloading CSV:", error)
    }
  }

  return (
    <div className="container mx-auto py-10">
      <DataTable<Team>
        columns={columns}
        fetcher={fetcherFunction}
        onCreate={() => setShowCreate(true)}
        handleDownload={downloadCSV}
        CreateComponent={CreateTeam}
        showCreate={showCreate}
        setShowCreate={setShowCreate}
        supabase={supabase}
      />
    </div>
  )
}

export default Teams
