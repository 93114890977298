import * as React from "react"
import { useForm, Controller } from "react-hook-form"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog"
import { Input } from "../ui/input"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select"
import { Button } from "../ui/button"
import { Label } from "../ui/label"
import { DBName } from "../../utils/func"
import { useToast } from "../ui/use-toast"

interface CreateItemProps {
  showCreate: boolean
  setShowCreate: (show: boolean) => void
  supabase: any
  callback?: () => void
}

const itemTypes = [
  { value: "Component", label: "Component" },
  { value: "Finished Product", label: "Finished Product" },
  { value: "BENCH WORK", label: "BENCH WORK" },
  { value: "SEMI FINISHED PRODUCTS", label: "SEMI FINISHED PRODUCTS" },
  { value: "RAW GOLD", label: "RAW GOLD" },
  { value: "GOLD", label: "GOLD" },
]

export function CreateItem({ showCreate, setShowCreate, supabase, callback }: CreateItemProps) {
  const { toast } = useToast()
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm()

  const onSubmit = async (formData: any) => {
    try {
      const id = Math.floor(Math.random() * 5000) + 1000
      const { error } = await supabase
        .from('Items')
        .insert([{
          id: id,
          item_name: formData.item_name,
          item_type: formData.item_type
        }])
      
      if (error) throw error

      toast({
        title: "Success",
        description: "Item created successfully",
        duration: 3000,
      })
      
      reset()
      setShowCreate(false)
      if (callback) callback()
    } catch (error: any) {
      console.error(error)
      toast({
        title: "Error",
        description: error.message || "Error creating Item",
        variant: "destructive",
      })
    }
  }

  return (
    <Dialog open={showCreate} onOpenChange={setShowCreate}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Create Item</DialogTitle>
        </DialogHeader>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="item_name">Name</Label>
            <Input
              id="item_name"
              {...register("item_name", { required: "Name is required" })}
            />
            {errors.item_name && (
              <span className="text-sm text-destructive">
                {errors.item_name.message as string}
              </span>
            )}
          </div>

          <div className="space-y-2">
            <Label htmlFor="item_type">Type</Label>
            <Controller
  name="item_type"
  control={control}
  rules={{ required: "Type is required" }}
  render={({ field }) => (
    <Select onValueChange={field.onChange} defaultValue={field.value}>
      <SelectTrigger className="w-full">
        <SelectValue placeholder="Select type" />
      </SelectTrigger>
      <SelectContent>
        {itemTypes.map((type) => (
          <SelectItem key={type.value} value={type.value}>
            {type.label}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )}
/>
            {errors.item_type && (
              <span className="text-sm text-destructive">
                {errors.item_type.message as string}
              </span>
            )}
          </div>

          <Button type="submit" className="w-full">
            Create
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  )
}
