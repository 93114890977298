/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  MantineReactTable,
  useMantineReactTable,
  MRT_RowSelectionState,
} from "mantine-react-table";
import React from "react";
import { DBName, VOView, generateColumns } from "../utils/func";
import styled from "styled-components";
import { Box, Button, MantineProvider, Group, Text } from "@mantine/core";
import ADrawer from "./Drawer";

const Wrapper = styled.div`
  width: 100%;
  overflow-x: auto;
`;

const Table = ({
  fetcher,
  onCreate,
  handleDownload,
  CreateComponent,
  showCreate,
  setShowCreate,
  supabase,
  onRowClick,
  onSave = null,
  multiRowSelection = false,
  selectedRows = {},
  setSelectedRows = (rowSelection: any) => {},
  rowId,
  refresh,
  showCreateButton = true,
  getRowColor = (row: any) => {},
  showEdit = false,
  setShowEdit = (showEdit: boolean) => {},
  selectedPayload = {},
  setSelectedPayload = (selectedPayload: any) => {},
  enableDrawer = true,
  showOnMultiSelect = false,
  onMutiSelectClick = () => {},
  tableName="",
  showToolbar = true,
}: any) => {
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 30 });
  const [total, setTotal] = useState(0);
  const [globalFilter, setGlobalFilter] = useState("");
  const [columns, setColumns] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [rowData, setRowData] = useState({});
  const [showDrawer, setShowDrawer] = useState(false);

  const getStoreTransactions = async ({
    pageIndex,
    pageSize,
    filters,
    sort,
  }: any) => {
    try {
      const { data, count } = await fetcher({
        pageIndex,
        pageSize,
        filters,
        sort,
      });
      const col = generateColumns(data);
      //@ts-ignore
      setColumns(col);
      setData(data);
      setTotal(count);
    } catch (error) {
      console.log(error);
      setData([]);
    }
  };

  useEffect(() => {
    console.log("useEffect");
    console.log(sorting);
    getStoreTransactions({
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize,
      filters: columnFilters,
      sort: sorting,
    });
  }, [
    pagination.pageIndex,
    pagination.pageSize,
    globalFilter,
    columnFilters,
    sorting,
  ]);

  const callback = async () => {
    await getStoreTransactions({
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize,
      filters: columnFilters,
      sort: sorting,
    });
    if(setShowCreate) setShowCreate(false);
    if(setShowEdit) setShowEdit(false);
  };

  useEffect(() => {
    setSelectedRows(rowSelection);
  }, [rowSelection]);

  useEffect(() => {
    callback();
  }, [refresh]);

  const table = useMantineReactTable({
    columns: columns.map((col: any) => ({
      ...col,
      filterVariant: col.accessorKey === 'id' ? 'number' : 'text',
    })),
    data,
    getRowId: rowId ? (row) => row[rowId] : undefined,
    onPaginationChange: setPagination,
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    rowCount: total,
    enableGlobalFilter: false,
    manualPagination: true,
    //@ts-ignore
    onColumnFiltersChange: setColumnFilters,
    //@ts-ignore
    onSortingChange: setSorting,
    manualFiltering: true,
    onGlobalFilterChange: setGlobalFilter,
    state: { pagination, globalFilter, columnFilters, rowSelection },
    renderTopToolbarCustomActions: ({ table }) => (
      !showToolbar ? null :
      <Box sx={{ display: "flex", gap: "16px", padding: "4px" }}>
        {showCreateButton && (
          <Button
            className="primary"
            onClick={() => {
              setShowCreate(true);
            }}
            variant="filled"
          >
            Create
          </Button>
        )}

        <Button
          color="dark"
          onClick={() => {
            handleDownload();
          }}
          variant="outline"
        >
          Download
        </Button>
        {showOnMultiSelect && (
          <Button
            color="dark"
            onClick={() => {
              onMutiSelectClick();
            }}
            variant="outline"
          >
            Dispatch
          </Button>
        )}
      </Box>
    ),
    mantineTableHeadRowProps: {
      sx: {
        border: "1px solid #dee2e6 !important",
        borderTop: "0px !important",
        height: "20px !important",
        padding: "8px !important",
        fontWeight: 500,
        position: 'sticky',
        top: 0,
        backgroundColor: '#FFF1DC',
        zIndex: 1,
      },
    },
    mantineTableHeadCellProps: {
      sx: {
        fontWeight: 500,
        padding: "8px !important",
        color: "#D68000 !important",
        backgroundColor: "#FFF1DC",
      },
    },
    mantineTableBodyCellProps: ({ row }) => ({
      sx: {
        padding: "4px 12px !important",
        backgroundColor: getRowColor(row.original),
        fontSize:"12px !important"
      },
    }),
    mantineTableBodyProps: {
      sx: {
        height: "100% !important",
      },
    },
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: (event) => {
        if (onRowClick) onRowClick(row);
        setRowData(row.original);
        if (enableDrawer) setShowDrawer(true);
      },
      sx: {
        cursor: "pointer",
      },
    }),
    mantineTopToolbarProps(props) {
      return {
        sx: {
          borderBottom: "1px solid #dee2e6 !important",
        },
      };
    },
    mantinePaperProps: {
      sx: {
        '& > div:nth-child(2)': {
          height: [VOView.KARIGAR, VOView.TEAM_VIEW, DBName.VENDOR_OUTWORK].includes(tableName) ? "calc(100vh - 159px) !important" : "calc(100vh - 129px) !important",
          overflowY: "auto",
        },
      },
    },
    enableStickyHeader: true,
    mantineTableContainerProps: {
      sx: {
        maxHeight: '100%',
        height: '100%',
      },
    },
  });

  return (
    <Wrapper>
      <MantineProvider
        theme={{
          primaryColor: "blue",
          primaryShade: 8,
          components: {
            MantineReactTable: {
              styles: {
                row: {
                  padding: "4px 12px !important",
                },
                head: {
                  padding: "4px 12px !important",
                  maxHeight: "20px",
                },
              },
            },
          },
        }}
      >
        <MantineReactTable table={table} />
      </MantineProvider>
      {showCreate &&
        React.createElement(CreateComponent, {
          callback: onSave ? onSave : callback,
          supabase,
          showCreate,
          setShowCreate,
        })}
      {showEdit &&
        React.createElement(CreateComponent, {
          callback: onSave ? onSave : callback,
          supabase,
          showCreate: showEdit,
          setShowCreate: setShowEdit,
          isEdit: true,
          payload: selectedPayload,
        })}
      {showDrawer && (
        <ADrawer
          opened={showDrawer}
          close={() => setShowDrawer(false)}
          data={rowData}
        />
      )}
    </Wrapper>
  );
};

const getFilterVariant = (columnId: string) => {
  switch (columnId) {
    case 'id':
      return 'number';
    default:
      return 'text';
  }
};

export default Table;
