import { useState } from "react"
import { DataTable } from "../components/ui/data-table/data-table"
import { KarigarProductModal } from "../components/modals/karigar-product-modal"
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib'
import Papa from "papaparse"
import { ColumnDef } from "@tanstack/react-table"
import { Button } from "../components/ui/button"
import { ArrowUpDown, FileDown } from "lucide-react"
import { Checkbox } from "../components/ui/checkbox"
import { DBName } from "../utils/func"
import { format, isValid, parse } from "date-fns"
import { useToast } from "../components/ui/use-toast"

interface KarigarProduct {
  "Target Date": string
  karigar_name: string
  Product: string
  theme: string
  "Loaded Date": string
  "Standard Weight": number
  total: number
  received: number
}

type Props = {
  supabase: any
  otherProps: any
  selectedRows: any
  setSelectedRows: any
  refresh: any
}

const KarigarProduct = ({
  supabase,
  otherProps,
  selectedRows,
  setSelectedRows,
  refresh,
}: Props) => {
  const [showModal, setShowModal] = useState(false)
  const [selectedRow, setSelectedRow] = useState<KarigarProduct | null>(null)
  const formatDateString = (dateStr: string | null) => {
    if (!dateStr) return ""
    try {
      const formats = ["yyyy-MM-dd", "dd-MMM-yy", "d-MMM-yy"]
      for (const dateFormat of formats) {
        const parsedDate = parse(dateStr, dateFormat, new Date())
        if (isValid(parsedDate)) {
          return format(parsedDate, "dd-MMM-yy")
        }
      }
      return dateStr
    } catch {
      return dateStr
    }
  }
  const columns: ColumnDef<KarigarProduct>[] = [
    {
      id: "select",
      header: ({ table }) => (
        <Checkbox
          checked={table.getIsAllPageRowsSelected()}
          onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
          aria-label="Select all"
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          checked={row.getIsSelected()}
          onCheckedChange={(value) => row.toggleSelected(!!value)}
          aria-label="Select row"
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "Target Date",
      header: ({ column }) => (
        <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
          Target Date
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      ),
      cell: ({ row }) => formatDateString(row.getValue("Target Date")),
      enableColumnFilter: true,
    },
    {
      accessorKey: "karigar_name",
      header: "Karigar Name",
      enableColumnFilter: true,
    },
    {
      accessorKey: "Product",
      header: "Product",
      enableColumnFilter: true,
    },
    {
      accessorKey: "theme",
      header: "Theme",
      enableColumnFilter: true,
    },
    {
      accessorKey: "Loaded Date",
      header: ({ column }) => (
        <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
          Loaded Date
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      ),
      cell: ({ row }) => formatDateString(row.getValue("Loaded Date")),
      enableColumnFilter: true,
    },
    {
      accessorKey: "Standard Weight",
      header: "Standard Weight",
    },
    {
      accessorKey: "total",
      header: "Total",
    },
    {
      accessorKey: "received",
      header: "Received",
    }
  ]

  const fetcherFunction = async ({ pageIndex, pageSize, filters, sort }: any) => {
    try {
      let query = supabase
        .from('karigarwise_view')
        .select('*', { count: 'exact' })
        .range(pageIndex * pageSize, (pageIndex + 1) * pageSize - 1)

      if (filters?.length) {
        filters.forEach((filter: any) => {
          query = query.ilike(filter.id, `%${filter.value}%`)
        })
      }

      if (sort?.length) {
        sort.forEach((sortObj: any) => {
          query = query.order(sortObj.id, { ascending: !sortObj.desc })
        })
      }

      const { data, count, error } = await query
      
      if (error) throw error

      return { 
        data: data || [], 
        count: count || 0 
      }
    } catch (error) {
      console.error('Error fetching data:', error)
      return { data: [], count: 0 }
    }
  }

  const handleRowClick = (row: KarigarProduct) => {
    // Check if the click was on a checkbox using event.target
    const target = document.activeElement;
    if (target?.closest('[role="checkbox"]')) {
      return;
    }
    
    setSelectedRow(row)
    setShowModal(true)
  }

  const downloadJobCards = async (selectedRows: KarigarProduct[], supabase: any) => {
    const pdfDoc = await PDFDocument.create()
    
    for (const row of selectedRows) {
      console.log("Processing product:", row.Product)
      
      const { data: productData, error: productError } = await supabase
        .from('products')
        .select('image')
        .eq('product_id', row.Product)
        .single()

      if (productError) {
        console.error('Error fetching product data:', productError)
      }

      console.log("Product image URL:", productData?.image)

      // Fetch product details and mapped items
      const { data: mappedItems } = await supabase
        .from('product_items_mapping')
        .select(`
          quantity,
          Items (
            item_name
          )
        `)
        .eq('product_id', row.Product)

      // Create a new page in landscape orientation
      const page = pdfDoc.addPage([842, 595]) // A4 landscape
      const { width, height } = page.getSize()
      const font = await pdfDoc.embedFont(StandardFonts.Helvetica)
      const fontSize = 12

      // Draw borders and headers
      // Product header
      page.drawRectangle({
        x: 50,
        y: height - 100,
        width: width - 350,
        height: 50,
        borderWidth: 1,
        borderColor: rgb(0, 0, 0),
      })
      page.drawText(`Product: ${row.Product}`, {
        x: 60,
        y: height - 70,
        size: 16,
        font,
      })

      // Karigar and Quantity boxes
      page.drawRectangle({
        x: 50,
        y: height - 160,
        width: (width - 350) / 2,
        height: 50,
        borderWidth: 1,
        borderColor: rgb(0, 0, 0),
      })
      page.drawText(`Assigned: ${row.karigar_name}`, {
        x: 60,
        y: height - 130,
        size: fontSize,
        font,
      })

      page.drawRectangle({
        x: 50 + (width - 350) / 2,
        y: height - 160,
        width: (width - 350) / 2,
        height: 50,
        borderWidth: 1,
        borderColor: rgb(0, 0, 0),
      })
      page.drawText(`Quantity: ${row.total}`, {
        x: 60 + (width - 350) / 2,
        y: height - 130,
        size: fontSize,
        font,
      })

      // Components Data Table - Adjusted spacing
      page.drawText('Components Data', {
        x: 50,
        y: height - 190, // Moved up to create more space
        size: 14,
        font,
      })

      const tableHeaders = ['Part', 'Quantity', 'Supplied']
      const columnWidth = (width - 350) / 3

      // Draw table headers - Adjusted spacing
      tableHeaders.forEach((header, index) => {
        page.drawRectangle({
          x: 50 + index * columnWidth,
          y: height - 230, // Kept the same position for the table
          width: columnWidth,
          height: 30,
          borderWidth: 1,
          borderColor: rgb(0, 0, 0),
        })
        page.drawText(header, {
          x: 60 + index * columnWidth,
          y: height - 215,
          size: fontSize,
          font,
        })
      })

      // Draw component rows
      mappedItems?.forEach((item: any, index: number) => {
        const yPos = height - 260 - (index * 30)
        // Draw cells
        tableHeaders.forEach((_, colIndex) => {
          page.drawRectangle({
            x: 50 + colIndex * columnWidth,
            y: yPos,
            width: columnWidth,
            height: 30,
            borderWidth: 1,
            borderColor: rgb(0, 0, 0),
          })
        })
        // Fill data
        page.drawText(item.Items.item_name, {
          x: 60,
          y: yPos + 10,
          size: fontSize,
          font,
        })
        page.drawText(item.quantity.toString(), {
          x: 60 + columnWidth,
          y: yPos + 10,
          size: fontSize,
          font,
        })
      })

      // Route Map Table
      const routeMapY = height - 420
      page.drawText('Route Map', {
        x: 50,
        y: routeMapY,
        size: 14,
        font,
      })

      const departments = ['Component Supply', 'Bench Work', 'Polishing', 'Cutting']
      departments.forEach((dept, index) => {
        const yPos = routeMapY - 40 - (index * 30)
        // Department column
        page.drawRectangle({
          x: 50,
          y: yPos,
          width: columnWidth,
          height: 30,
          borderWidth: 1,
          borderColor: rgb(0, 0, 0),
        })
        page.drawText(dept, {
          x: 60,
          y: yPos + 10,
          size: fontSize,
          font,
        })
        // Empty columns
        for (let i = 1; i < 3; i++) {
          page.drawRectangle({
            x: 50 + i * columnWidth,
            y: yPos,
            width: columnWidth,
            height: 30,
            borderWidth: 1,
            borderColor: rgb(0, 0, 0),
          })
        }
      })

      // Product Image box
      const imageBox = {
        x: width - 280,
        y: height - 300,
        width: 250,
        height: 250,
      }
      page.drawRectangle({
        ...imageBox,
        borderWidth: 1,
        borderColor: rgb(0, 0, 0),
      })

      if (productData?.image) {
        try {
          // Fetch the image using fetch API
          const imageResponse = await fetch(productData.image)
          if (!imageResponse.ok) {
            throw new Error(`Failed to fetch image: ${imageResponse.statusText}`)
          }

          const imageBytes = await imageResponse.arrayBuffer()
          
          // Determine image type from URL
          const isJpg = productData.image.toLowerCase().endsWith('.jpg') || 
                       productData.image.toLowerCase().endsWith('.jpeg')
          
          let image
          try {
            if (isJpg) {
              image = await pdfDoc.embedJpg(imageBytes)
            } else {
              image = await pdfDoc.embedPng(imageBytes)
            }
          } catch (embedError) {
            console.error('Error embedding image:', embedError)
            // Try the other format if the first attempt fails
            image = isJpg ? 
              await pdfDoc.embedPng(imageBytes) : 
              await pdfDoc.embedJpg(imageBytes)
          }

          // Get original dimensions
          const imageDims = image.scale(1)
          
          // Calculate scaling to fit box while maintaining aspect ratio
          const scale = Math.min(
            imageBox.width / imageDims.width,
            imageBox.height / imageDims.height
          )

          // Calculate centered position
          const scaledWidth = imageDims.width * scale
          const scaledHeight = imageDims.height * scale
          const xOffset = (imageBox.width - scaledWidth) / 2
          const yOffset = (imageBox.height - scaledHeight) / 2

          console.log('Drawing image with dimensions:', {
            x: imageBox.x + xOffset,
            y: imageBox.y + yOffset,
            width: scaledWidth,
            height: scaledHeight,
            originalWidth: imageDims.width,
            originalHeight: imageDims.height,
            scale
          })

          page.drawImage(image, {
            x: imageBox.x + xOffset,
            y: imageBox.y + yOffset,
            width: scaledWidth,
            height: scaledHeight
          })
          
          console.log("Image successfully embedded")
        } catch (error) {
          console.error('Error processing image:', error)
          // Draw an error message in the image box
          page.drawText('Error loading image', {
            x: imageBox.x + (imageBox.width - 100) / 2,
            y: imageBox.y + (imageBox.height / 2),
            size: 12,
            font,
            color: rgb(0.7, 0, 0),
          })
        }
      } else {
        console.log("No image data available for product:", row.Product)
        page.drawText('No image available', {
          x: imageBox.x + (imageBox.width - 100) / 2,
          y: imageBox.y + (imageBox.height / 2),
          size: 12,
          font,
          color: rgb(0.5, 0.5, 0.5),
        })
      }
    }

    const pdfBytes = await pdfDoc.save()
    const blob = new Blob([pdfBytes], { type: 'application/pdf' })
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = `job_cards_${new Date().toISOString()}.pdf`
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    URL.revokeObjectURL(url)
  }

  return (
    <div className="container mx-auto py-10">
      <DataTable<KarigarProduct>
        columns={columns}
        fetcher={fetcherFunction}
        onRowClick={handleRowClick}
        multiRowSelection={true}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        additionalButtons={(table: any) => (
          table.getSelectedRowModel().rows.length > 0 && (
            <Button
              variant="outline"
              size="sm"
              onClick={() => downloadJobCards(
                table.getSelectedRowModel().rows.map((row: any) => row.original),
                supabase
              )}
            >
              <FileDown className="h-4 w-4 mr-2" />
              Download Job Cards
            </Button>
          )
        )}
      />
      {showModal && selectedRow && (
        <KarigarProductModal
          supabase={supabase}
          showModal={showModal}
          setShowModal={setShowModal}
          data={{
            "Karigar Name": selectedRow.karigar_name,
            "Product": selectedRow.Product,
            "Target Date": formatDateString(selectedRow["Target Date"])
          }}
        />
      )}
    </div>
  )
}

export default KarigarProduct
