import { useState } from "react"
import { DataTable } from "../components/ui/data-table/data-table"
import { CreateProduct } from "../components/modals/create-product"
import { ColumnDef } from "@tanstack/react-table"
import { Button } from "../components/ui/button"
import { ArrowUpDown } from "lucide-react"
import { Checkbox } from "../components/ui/checkbox"
import { Card, CardContent } from "../components/ui/card"
import Papa from "papaparse"

interface Product {
  product_id: string
  created_at: string
  image: string
}

interface MappedItem {
  id: number
  item_id: number
  item_name: string
  quantity: number
}

const Products = ({ supabase }: { supabase: any }) => {
  const [showCreate, setShowCreate] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null)
  const [mappedItems, setMappedItems] = useState<MappedItem[]>([])

  const columns: ColumnDef<Product>[] = [
    {
      id: "select",
      header: ({ table }) => (
        <Checkbox
          checked={table.getIsAllPageRowsSelected()}
          onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
          aria-label="Select all"
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          checked={row.getIsSelected()}
          onCheckedChange={(value) => row.toggleSelected(!!value)}
          aria-label="Select row"
        />
      ),
      enableSorting: false,
      enableHiding: false,
      size: 40,
    },
    {
      accessorKey: "product_id",
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Product ID
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      ),
      enableColumnFilter: true,
    },
    {
      accessorKey: "created_at",
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Created At
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      ),
      cell: ({ row }) => new Date(row.getValue("created_at")).toLocaleString(),
      enableColumnFilter: true,
    },
  ]

  const handleRowClick = async (row: Product) => {
    setSelectedProduct(row)
    const { data, error } = await supabase
      .from('product_items_mapping')
      .select(`
        id,
        item_id,
        quantity,
        Items (
          id,
          item_name
        )
      `)
      .eq('product_id', row.product_id)

    if (!error && data) {
      setMappedItems(data.map((item: any) => ({
        id: item.id,
        item_id: item.item_id,
        item_name: item.Items.item_name,
        quantity: item.quantity
      })))
    }
  }

  const fetcherFunction = async ({ pageIndex, pageSize, filters, sort }: any) => {
    try {
      let query = supabase
        .from('products')
        .select('*', { count: 'exact' })
        .range(pageIndex * pageSize, (pageIndex + 1) * pageSize - 1)

      if (filters?.length) {
        filters.forEach((filter: any) => {
          query = query.ilike(filter.id, `%${filter.value}%`)
        })
      }

      if (sort?.length) {
        sort.forEach((s: any) => {
          query = query.order(s.id, { ascending: !s.desc })
        })
      }

      const { data, error, count } = await query
      if (error) throw error

      return { 
        data: data || [], 
        count: count || 0 
      }
    } catch (error) {
      console.error('Error fetching data:', error)
      return { data: [], count: 0 }
    }
  }

  const downloadCSV = async () => {
    const { data } = await supabase.from('products').select('*')
    const csv = Papa.unparse(data)
    const blob = new Blob([csv], { type: 'text/csv' })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = 'products.csv'
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  return (
    <div className="container mx-auto py-10">
      <div className="grid grid-cols-3 gap-4">
        <div className="col-span-2">
          <DataTable
            columns={columns}
            fetcher={fetcherFunction}
            onCreate={() => setShowCreate(true)}
            handleDownload={downloadCSV}
            CreateComponent={CreateProduct}
            showCreate={showCreate}
            setShowCreate={setShowCreate}
            supabase={supabase}
            onRowClick={handleRowClick}
          />
        </div>
        <div className="col-span-1">
          {selectedProduct && (
            <Card>
              <CardContent className="p-4 space-y-4">
                <h3 className="text-lg font-semibold">{selectedProduct.product_id}</h3>
                {selectedProduct.image && (
                  <div className="relative w-full h-48">
                    <img
                      src={selectedProduct.image}
                      alt={selectedProduct.product_id}
                      className="w-full h-full object-contain rounded-md"
                      onError={(e) => {
                        const target = e.target as HTMLImageElement;
                        target.src = '/placeholder-image.png'; // Fallback image
                      }}
                    />
                  </div>
                )}
                <div>
                  <h4 className="font-medium mb-2">Mapped Items</h4>
                  <div className="space-y-2">
                    {mappedItems.map((item) => (
                      <div key={item.id} className="flex justify-between items-center p-2 bg-gray-50 rounded">
                        <span>{item.item_name}</span>
                        <span className="text-sm text-gray-600">Qty: {item.quantity}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </CardContent>
            </Card>
          )}
        </div>
      </div>
    </div>
  )
}

export default Products
