"use client"

import * as React from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { cn } from "../lib/utils"
import {
  ChevronDown,
  FileText,
  Users,
  LogOut,
  AlertTriangle,
  LayoutDashboard,
  ArrowRightLeft,
  ArrowUpDown,
  UserCircle,
  SettingsIcon,
} from "lucide-react"
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "../components/ui/collapsible"
import { Button } from "./ui/button"
import { LSKeys } from "../utils/func"
import { useToast } from "./ui/use-toast"
import { DashboardIcon } from "@radix-ui/react-icons"

interface SidebarProps extends React.HTMLAttributes<HTMLDivElement> {}

const ACTIVE_BG_COLOR = "bg-[#FFF5E9]"

const menuItems = [
  {
    name: "Stock Report",
    path: "/report/380b420a-729d-49dc-a5b8-44a9c28c9832",
    icon: FileText,
  },
  {
    name: "Dashboard",
    path: "/report/9d1be61b-bd6e-4fc7-9d55-cb13ccf8fb42",
    icon: DashboardIcon,
  },
  {
    name: "Store Transactions",
    path: "/store_transaction",
    icon: ArrowRightLeft,
  },
  {
    name: "External Transactions",
    path: "/external_transaction",
    icon: ArrowUpDown,
  },
  {
    name: "Vendor Outwork",
    path: "/vendor_outwork",
    icon: UserCircle,
    subItems: [
      {
        name: "Vendor Outwork",
        path: "/vendor_outwork?tab=vendor_outwork",
      },
      {
        name: "Karigar",
        path: "/vendor_outwork?tab=karigar",
      },
      {
        name: "Teamwise View",
        path: "/vendor_outwork?tab=teamwise",
      },
    ],
  },
  {
    name: "Settings",
    path: "/settings",
    icon: SettingsIcon,
    subItems: [
      {
        name: "Items",
        path: "/items",
      },
      {
        name: "Products",
        path: "/products",
      },
      {
        name: "Users",
        path: "/users",
      },
      {
        name: "Teams",
        path: "/teams",
      },
    ],
  },
]

export function Sidebar({ className }: SidebarProps) {
  const navigate = useNavigate()
  const location = useLocation()
  const { toast } = useToast()
  const [openItems, setOpenItems] = React.useState<string[]>([])

  const toggleItem = (itemName: string) => {
    setOpenItems(prev => 
      prev.includes(itemName) 
        ? prev.filter(item => item !== itemName)
        : [...prev, itemName]
    )
  }

  const isActive = (path: string) => {
    return location.pathname + location.search === path
  }

  const logout = () => {
    localStorage.removeItem(LSKeys.USER)
    toast({
      title: "Success",
      description: "Logged out successfully",
    })
    navigate("/login")
  }

  return (
    <div className={cn("flex flex-col min-h-screen border-r bg-background", className)}>
      <div className="flex-1 space-y-4 py-4">
        <div className="px-3 py-2">
          <div className="mb-8 px-4">
            <img
              className="h-8"
              alt="Ausum Logo"
              src="https://i.ibb.co/N9P4xgG/ausum-2.png"
            />
          </div>
          <div className="space-y-1">
            {menuItems.map((item) => (
              item.subItems ? (
                <Collapsible
                  key={item.name}
                  open={openItems.includes(item.name)}
                  onOpenChange={() => toggleItem(item.name)}
                >
                  <CollapsibleTrigger asChild>
                    <Button
                      variant="ghost"
                      className={cn(
                        "w-full justify-between hover:bg-muted/50",
                        "font-medium tracking-wide",
                        openItems.includes(item.name) && "bg-muted/50",
                        item.subItems.some(subItem => isActive(subItem.path)) && ACTIVE_BG_COLOR
                      )}
                    >
                      <div className="flex items-center">
                        <item.icon className="h-4 w-4 mr-2" />
                        <span className="text-sm">{item.name}</span>
                      </div>
                      <ChevronDown className={cn(
                        "h-4 w-4 transition-transform duration-200",
                        openItems.includes(item.name) && "rotate-180"
                      )} />
                    </Button>
                  </CollapsibleTrigger>
                  <CollapsibleContent className="space-y-1">
                    {item.subItems.map((subItem) => (
                      <Button
                        key={subItem.path}
                        variant="ghost"
                        className={cn(
                          "w-full justify-start pl-9 font-normal hover:bg-muted/50",
                          "text-sm font-light text-muted-foreground",
                          isActive(subItem.path) && [
                            ACTIVE_BG_COLOR,
                            "text-primary font-medium"
                          ]
                        )}
                        onClick={() => navigate(subItem.path)}
                      >
                        <span>{subItem.name}</span>
                      </Button>
                    ))}
                  </CollapsibleContent>
                </Collapsible>
              ) : (
                <Button
                  key={item.name}
                  variant="ghost"
                  className={cn(
                    "w-full justify-start hover:bg-muted/50",
                    "font-semibold tracking-wide",
                    isActive(item.path) && ACTIVE_BG_COLOR
                  )}
                  onClick={() => navigate(item.path)}
                >
                  <item.icon className="h-4 w-4 mr-2" />
                  <span className="text-sm">{item.name}</span>
                </Button>
              )
            ))}
          </div>
        </div>
      </div>
      <div className="sticky bottom-0 border-t bg-background p-3">
        <Button
          variant="ghost"
          className="w-full justify-start hover:bg-muted/50 font-medium"
          onClick={logout}
        >
          <LogOut className="h-4 w-4 mr-2" />
          <span className="text-sm">Logout</span>
        </Button>
      </div>
    </div>
  )
}
