import { useEffect, useState, useCallback } from "react"
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../ui/dialog"
import { Button } from "../ui/button"
import { Input } from "../ui/input"
import { Label } from "../ui/label"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select"
import { useForm, Controller } from "react-hook-form"
import { format, isValid, parse } from "date-fns"
import { DBName } from "../../utils/func"
import { useToast } from "../ui/use-toast"
import { DataTable } from "../ui/data-table/data-table"
import { ColumnDef } from "@tanstack/react-table"
import { ArrowUpDown } from "lucide-react"
import { Checkbox } from "../ui/checkbox"

interface SelectOption {
  value: string
  label: string
}

interface KarigarProductModalProps {
  supabase: any
  showModal: boolean
  setShowModal: (show: boolean) => void
  data: {
    "Karigar Name": string
    "Product": string,
    "Target Date": string
  }
}

interface VendorOutwork {
  id: number
  batch_number: string
  weight: number
  created_at: string
}
const formatDateString = (dateStr: string | null) => {
    if (!dateStr) return ""
    try {
      const formats = ["yyyy-MM-dd", "dd-MMM-yy", "d-MMM-yy"]
      for (const dateFormat of formats) {
        const parsedDate = parse(dateStr, dateFormat, new Date())
        if (isValid(parsedDate)) {
          return format(parsedDate, "dd-MMM-yy")
        }
      }
      return dateStr
    } catch {
      return dateStr
    }
  }
const vendorOutworkColumns: ColumnDef<VendorOutwork>[] = [
    {
      id: "select",
      header: ({ table }) => (
        <Checkbox
          checked={table.getIsAllPageRowsSelected()}
          onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
          aria-label="Select all"
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          checked={row.getIsSelected()}
          onCheckedChange={(value) => row.toggleSelected(!!value)}
          aria-label="Select row"
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "Order Number",
      header: ({ column }) => (
        <Button variant="outline" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
          Order Number
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      ),
      enableColumnFilter: true,
    },
    {
      accessorKey: "Target Date",
      header: ({ column }) => (
        <Button variant="outline" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
          Target Date
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      ),
      cell: ({ row }) => formatDateString(row.getValue("Target Date")),
      enableColumnFilter: true,
    },
    {
      accessorKey: "Line No",
      header: "Line No",
      enableColumnFilter: true,
    },
    {
      accessorKey: "Order Type",
      header: "Order Type",
      enableColumnFilter: true,
    },
    {
      accessorKey: "Batch Number",
      header: "Batch Number",
      enableColumnFilter: true,
    },
    {
      accessorKey: "Product",
      header: "Product",
      enableColumnFilter: true,
    },
    {
      accessorKey: "Description",
      header: "Description",
      enableColumnFilter: true,
    },
    {
      accessorKey: "UOM1",
      header: "UOM1",
    },
    {
      accessorKey: "UOM2",
      header: "UOM2",
    },
    {
      accessorKey: "Qty",
      header: "Qty",
    },
    {
      accessorKey: "Pure Wt",
      header: "Pure Wt",
    },
    {
      accessorKey: "BOM Dia Qty",
      header: "BOM Dia Qty",
    },
    {
      accessorKey: "BOM Clr Qty",
      header: "BOM Clr Qty",
    },
    {
      accessorKey: "BOM Tot Qty",
      header: "BOM Tot Qty",
    },
    {
      accessorKey: "Issue Tot Qty",
      header: "Issue Tot Qty",
    },
    {
      accessorKey: "Stn Wt/Gms",
      header: "Stn Wt/Gms",
    },
    {
      accessorKey: "Value",
      header: "Value",
    },
    {
      accessorKey: "Warehouse",
      header: "Warehouse",
      enableColumnFilter: true,
    },
    {
      accessorKey: "Vendor Short Name",
      header: "Vendor Short Name",
      enableColumnFilter: true,
    },
    {
      accessorKey: "Vendor Site",
      header: "Vendor Site",
      enableColumnFilter: true,
    },
    {
      accessorKey: "JO No",
      header: "JO No",
      enableColumnFilter: true,
    },
    {
      accessorKey: "JO Date",
      header: ({ column }) => (
        <Button variant="outline" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
          JO Date
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      ),
      cell: ({ row }) => formatDateString(row.getValue("JO Date")),
      enableColumnFilter: true,
    },
    {
      accessorKey: "JO Status",
      header: "JO Status",
      enableColumnFilter: true,
    },
    {
      accessorKey: "Inw No",
      header: "Inw No",
      enableColumnFilter: true,
    },
    {
      accessorKey: "Inward Date",
      header: ({ column }) => (
        <Button variant="outline" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
          Inward Date
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      ),
      cell: ({ row }) => formatDateString(row.getValue("Inward Date")),
      enableColumnFilter: true,
    },
    {
      accessorKey: "Inward Status",
      header: "Inward Status",
      enableColumnFilter: true,
    },
    {
      accessorKey: "Transfer No",
      header: "Transfer No",
      enableColumnFilter: true,
    },
    {
      accessorKey: "Material Posted",
      header: "Material Posted",
      enableColumnFilter: true,
    },
    {
      accessorKey: "Standard Weight",
      header: "Standard Weight",
    },
    {
      accessorKey: "Prod Route",
      header: "Prod Route",
      enableColumnFilter: true,
    },
    {
      accessorKey: "Card Release",
      header: "Card Release",
      enableColumnFilter: true,
    },
    {
      accessorKey: "Booked Date",
      header: ({ column }) => (
        <Button variant="outline" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
          Booked Date
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      ),
      cell: ({ row }) => formatDateString(row.getValue("Booked Date")),
      enableColumnFilter: true,
    },
    {
      accessorKey: "Karigar Name",
      header: "Karigar Name",
      enableColumnFilter: true,
    },
    {
      accessorKey: "Loaded Date",
      header: ({ column }) => (
        <Button variant="outline" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
          Loaded Date
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      ),
      cell: ({ row }) => formatDateString(row.getValue("Loaded Date")),
      enableColumnFilter: true,
    },
    {
      accessorKey: "Received Date",
      header: ({ column }) => (
        <Button variant="outline" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
          Received Date
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      ),
      cell: ({ row }) => formatDateString(row.getValue("Received Date")),
      enableColumnFilter: true,
    },
    {
      accessorKey: "Status",
      header: "Status",
      enableColumnFilter: true,
    }
  ]

export function KarigarProductModal({
  supabase,
  showModal,
  setShowModal,
  data,
}: KarigarProductModalProps) {
  const { toast } = useToast()
  const { control, handleSubmit, register, formState: { errors } } = useForm({
    defaultValues: {
      from: "",
      to: "",
      item: "",
      weight: "",
      "QC Pass": "",
      "QC Fail Reason": "",
      "QC Remarks": ""
    }
  })
  const [fromUsers, setFromUsers] = useState<SelectOption[]>([])
  const [toUsers, setToUsers] = useState<SelectOption[]>([])
  const [items, setItems] = useState<SelectOption[]>([])
  const [tableInstance, setTableInstance] = useState<any>(null)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const fetchUserFrom = useCallback(async () => {
    console.log("fetchUserFrom called");
    try {
      console.log("Fetching for karigar:", data["Karigar Name"])
      
      const { data: userData, error: userError } = await supabase
        .from(DBName.USERS)
        .select("team")
        .eq("name", data["Karigar Name"])
        .single()

      if (userError) throw userError

      if (userData?.team) {
        const { data: teamUsers, error: teamError } = await supabase
          .from(DBName.USERS)
          .select("id, name")
          .eq("team", userData.team)

        if (teamError) throw teamError

        setFromUsers(
          teamUsers.map((user: any) => ({
            value: user.id.toString(),
            label: user.name,
          }))
        )
      }
    } catch (error: any) {
      console.error("Error fetching users:", error)
      throw error
    }
  }, [data, supabase])

  const fetchUserTo = useCallback(async () => {
    console.log("fetchUserTo called");
    try {
      const { data: users, error } = await supabase
        .from(DBName.USERS)
        .select("name, id")
        .eq("team", 1)

      if (error) throw error

      setToUsers(users.map((user: any) => ({
        value: user.id.toString(),
        label: user.name,
      })))
    } catch (error) {
      console.error("Error fetching to users:", error)
      throw error
    }
  }, [supabase])

  const fetchItems = useCallback(async () => {
    console.log("fetchItems called");
    try {
      const { data: items, error } = await supabase
        .from(DBName.ITEMS)
        .select("id, item_name")

      if (error) throw error

      setItems(items.map((item: any) => ({
        value: item.id.toString(),
        label: item.item_name,
      })))
    } catch (error) {
      console.error("Error fetching items:", error)
      throw error
    }
  }, [supabase])

  useEffect(() => {
    console.log("useEffect triggered with showModal:", showModal, "and data:", data);
    if (showModal && data) {
      console.log("Modal opened, fetching data...");
      Promise.all([
        fetchUserFrom(),
        fetchUserTo(),
        fetchItems()
      ]).catch(error => {
        console.error("Error fetching dropdown data:", error)
        toast({
          title: "Error",
          description: "Failed to load dropdown options",
          variant: "destructive",
        })
      })
    }
  }, [showModal, data, fetchUserFrom, fetchUserTo, fetchItems])

  const handleTableMount = (table: any) => {
    console.log("Table mounted:", table)
    setTableInstance(table)
  }

  const onSubmit = async (formData: any) => {
    console.log("Form submitted with data:", formData)
    try {
      if (!tableInstance) {
        console.error("Table is not initialized:", tableInstance)
        throw new Error("Please wait for the table to load")
      }

      const selectedRows = tableInstance.getSelectedRowModel().rows
      console.log("Selected rows:", selectedRows)

      if (!selectedRows.length) {
        throw new Error("Please select at least one row")
      }

      setIsSubmitting(true)

      const timestamp = new Date().getTime()
      const groupId = `KS${timestamp}`

      // Log the operations we're about to perform
      console.log("Starting database operations with group ID:", groupId)

      // 1. Update vendor_outwork status
      const batchUpdates = selectedRows.map((row: any) => ({
        "Batch Number": row.original["Batch Number"],
        "Status": 'Stores'
      }))
      
      console.log("Updating vendor_outwork with:", batchUpdates)
      const { error: updateError } = await supabase
        .from(DBName.VENDOR_OUTWORK)
        .upsert(batchUpdates, { onConflict: 'Batch Number' })

      if (updateError) throw updateError

      // 2. Add records to vendor_outwork_log
      const logEntries = selectedRows.map((row: any) => ({
        "Batch Number": row.original["Batch Number"],
        "Status": 'Stores',
        "Group Id": groupId,
        created_at: new Date().toISOString()
      }))

      console.log("Adding to vendor_outwork_log:", logEntries)
      const { error: logError } = await supabase
        .from(DBName.VENDOR_OUTWORK_LOG)
        .insert(logEntries)

      if (logError) throw logError

      // 3. Add record to store_transactions
      const { data: userData, error: userError } = await supabase
        .from(DBName.USERS)
        .select("team")
        .eq("name", data["Karigar Name"])
        .single()

      if (userError) throw userError
      const transactionData = {
        from: userData.team,
        to:1,
        issuer: formData.from,
        receiver: formData.to,
        item_id: formData.item,
        weight: formData.weight,
        created_at: new Date().toISOString()
      }

      console.log("Adding to store_transactions:", transactionData)
      const { error: transactionError } = await supabase
        .from(DBName.STORE_TRANSACTIONS)
        .insert(transactionData)

      if (transactionError) throw transactionError

      console.log("All operations completed successfully")
      toast({
        title: "Success",
        description: "Records updated successfully",
      })
      setShowModal(false)
    } catch (error: any) {
      console.error("Submit error:", error)
      toast({
        title: "Error",
        description: error.message || "Failed to submit form",
        variant: "destructive",
      })
    } finally {
      setIsSubmitting(false)
    }
  }

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      console.log("Form validation errors:", errors)
    }
  }, [errors])

  return (
    <Dialog open={showModal} onOpenChange={setShowModal}>
  <DialogContent className="max-w-4xl max-h-[90vh] flex flex-col">
    <DialogHeader>
      <DialogTitle>Process Karigar Product</DialogTitle>
    </DialogHeader>
        {/* Main scrollable content */}
    <div className="flex-1 overflow-y-auto">
      <form 
        onSubmit={(e) => {
          console.log("Form submission attempted")
          handleSubmit(onSubmit)(e)
        }} 
        className="space-y-6"
      >
        <div className="pr-2">
              {/* First Row */}
              <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <Label>From User</Label>
                <Controller
                  name="from"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select 
                      value={field.value?.toString() || ""}
                      onValueChange={field.onChange}
                    >
                      <SelectTrigger>
                        <SelectValue>
                          {fromUsers.find(user => user.value === field.value)?.label || "Select user"}
                        </SelectValue>
                      </SelectTrigger>
                      <SelectContent>
                        {fromUsers.map((user) => (
                          <SelectItem key={user.value} value={user.value}>
                            {user.label}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  )}
                />
              </div>
              
              <div className="space-y-2">
                <Label>To User</Label>
                <Controller
                  name="to"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select 
                      value={field.value?.toString() || ""}
                      onValueChange={field.onChange}
                    >
                      <SelectTrigger>
                        <SelectValue>
                          {toUsers.find(user => user.value === field.value)?.label || "Select user"}
                        </SelectValue>
                      </SelectTrigger>
                      <SelectContent>
                        {toUsers.map((user) => (
                          <SelectItem key={user.value} value={user.value}>
                            {user.label}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  )}
                />
              </div>
            </div>

            {/* Second Row */}
            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <Label>Item</Label>
                <Controller
                  name="item"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select 
                      value={field.value?.toString() || ""}
                      onValueChange={field.onChange}
                    >
                      <SelectTrigger>
                        <SelectValue>
                          {items.find(item => item.value === field.value)?.label || "Select item"}
                        </SelectValue>
                      </SelectTrigger>
                      <SelectContent>
                        {items.map((item) => (
                          <SelectItem key={item.value} value={item.value}>
                            {item.label}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  )}
                />
              </div>
              
              <div className="space-y-2">
                <Label>Weight</Label>
                <Input
                  type="number"
                  step="0.01"
                  {...register("weight", { required: true })}
                />
              </div>
            </div>

            {/* Third Row */}
            <div className="grid grid-cols-3 gap-4">
              <div className="space-y-2">
                <Label>QC Pass</Label>
                <Controller
                  name="QC Pass"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select 
                      value={field.value?.toString() || ""}
                      onValueChange={field.onChange}
                    >
                      <SelectTrigger>
                        <SelectValue>
                          {field.value ? "Yes" : "No"}
                        </SelectValue>
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="true">Yes</SelectItem>
                        <SelectItem value="false">No</SelectItem>
                      </SelectContent>
                    </Select>
                  )}
                />
              </div>
              
              <div className="space-y-2">
                <Label>QC Issue</Label>
                <Controller
                  name="QC Fail Reason"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select 
                      value={field.value?.toString() || ""}
                      onValueChange={field.onChange}
                    >
                      <SelectTrigger>
                        <SelectValue>
                          {field.value || "Select issue"}
                        </SelectValue>
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="Wrong weight">Wrong weight</SelectItem>
                        <SelectItem value="Bad soldering">Bad soldering</SelectItem>
                        <SelectItem value="Stonework issue">Stonework issue</SelectItem>
                      </SelectContent>
                    </Select>
                  )}
                />
              </div>
              
              <div className="space-y-2">
                <Label>QC Remarks</Label>
                <Input {...register("QC Remarks")} placeholder="Enter remarks" />
              </div>
            </div>

          <div className="border rounded-lg">
            <DataTable
              columns={vendorOutworkColumns}
              onTableMount={handleTableMount}
              fetcher={async ({ pageIndex, pageSize, filters, sort }) => {
                let query = supabase
                  .from(DBName.VENDOR_OUTWORK)
                  .select('*', { count: 'exact' })
                  .eq('Karigar Name', data['Karigar Name'])
                  .eq('Product', data.Product)
                  .eq('Target Date', data["Target Date"])
                  .range(pageIndex * pageSize, (pageIndex + 1) * pageSize - 1)

                if (sort?.length) {
                  sort.forEach((sortObj: any) => {
                    query = query.order(sortObj.id, { ascending: !sortObj.desc })
                  })
                }
                
                const { data: result, count, error } = await query
                if (error) throw error

                return { 
                  data: result || [], 
                  count: count || 0
                }
              }}
            />
          </div>
          </div>
          {/* Submit button - Fixed at bottom */}
        <div className="sticky bottom-0 bg-white pt-4 pb-2">
          <Button 
            type="submit"
            className="w-full"
            onClick={() => console.log("Submit button clicked")}
          >
            Submit
          </Button>
        </div>
      </form>
    </div>
  </DialogContent>
</Dialog>
  )
}