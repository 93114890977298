"use client"

import * as React from "react"
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"

import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../table"
import { DataTablePagination } from "./data-table-pagination"
import { DataTableToolbar } from "./data-table-toolbar"
import { Button } from "../button"
import "./data-table.css";

interface DataTableProps<TData> {
  columns: ColumnDef<TData, any>[]
  fetcher: (options: {
    pageIndex: number
    pageSize: number
    filters: any[]
    sort: any[]
  }) => Promise<{ data: TData[]; count: number }>
  onCreate?: () => void
  handleDownload?: (table: any) => void
  CreateComponent?: React.ComponentType<any>
  showCreate?: boolean
  setShowCreate?: (show: boolean) => void
  supabase?: any
  multiRowSelection?: boolean
  selectedRows?: any[]
  setSelectedRows?: (rows: any[]) => void
  onRowClick?: (row: TData) => void
  onTableMount?: (table: any) => void
  additionalButtons?: (table: any) => React.ReactNode
}

export function DataTable<TData>({
  columns,
  fetcher,
  onCreate,
  additionalButtons,
  handleDownload,
  CreateComponent,
  showCreate,
  setShowCreate,
  supabase,
  onRowClick,
  onTableMount,
}: DataTableProps<TData>) {
  const [data, setData] = React.useState<TData[]>([])
  const [totalRows, setTotalRows] = React.useState(0)
  const [rowSelection, setRowSelection] = React.useState({})
  const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>({})
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([])
  const [sorting, setSorting] = React.useState<SortingState>([])
  const [{ pageIndex, pageSize }, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 10,
  })

  const fetchData = async (options: {
    pageIndex: number
    pageSize: number
    filters: any[]
    sort: any[]
  }) => {
    const result = await fetcher(options)
    setData(result.data)
    setTotalRows(result.count)
  }

  React.useEffect(() => {
    fetchData({
      pageIndex,
      pageSize,
      filters: columnFilters,
      sort: sorting,
    })
  }, [fetcher, pageIndex, pageSize, columnFilters, sorting])

  const table = useReactTable({
    data,
    columns,
    pageCount: Math.ceil(totalRows / pageSize),
    state: {
      sorting,
      columnVisibility,
      rowSelection,
      columnFilters,
      pagination: {
        pageIndex,
        pageSize,
      },
    },
    enableRowSelection: true,
    enableColumnResizing: true,
    columnResizeMode: "onChange",
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    manualPagination: true,
    manualFiltering: true,
    manualSorting: true,
  })

  React.useEffect(() => {
    if (table && onTableMount) {
      onTableMount(table)
    }
  }, [table, onTableMount])

  return (
    <div className="flex flex-col h-[calc(100vh-4rem)]">
      <div className="w-full bg-white">
        <div className="pb-4">
          <DataTableToolbar 
            table={table} 
            showCreateButton={!!onCreate}
            setShowCreate={setShowCreate}
            handleDownload={() => handleDownload?.(table)}
            additionalButtons={additionalButtons}
          />
        </div>
      </div>

      <div className="flex-1 border border-border overflow-hidden">
        <div className="h-full overflow-auto">
          <Table>
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id} className="sticky-header">
                  {headerGroup.headers.map((header) => (
                    <TableHead 
                      key={header.id} 
                      style={{ 
                        width: header.getSize(),
                        padding: '12px',
                        fontSize: '0.875rem',
                      }}
                    >
                      {header.isPlaceholder ? null : (
                        <div className="flex items-center justify-between">
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          <div
                            onMouseDown={header.getResizeHandler()}
                            onTouchStart={header.getResizeHandler()}
                            className={`absolute right-0 top-0 h-full w-1 cursor-col-resize select-none touch-none hover:bg-neutral-400 ${
                              header.column.getIsResizing() ? 'bg-neutral-400' : ''
                            }`}
                          />
                        </div>
                      )}
                    </TableHead>
                  ))}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                    className={onRowClick ? "cursor-pointer hover:bg-muted" : ""}
                    onClick={() => onRowClick?.(row.original)}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell 
                        key={cell.id} 
                        style={{ 
                          width: cell.column.getSize(),
                          padding: '8px 12px',
                          fontSize: '0.875rem',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className="h-24 text-center"
                  >
                    No results.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </div>

      <div className="w-full bg-white border-t py-2">
        <DataTablePagination table={table} />
      </div>

      {CreateComponent && showCreate && (
        <CreateComponent
          supabase={supabase}
          showCreate={showCreate}
          setShowCreate={setShowCreate}
          callback={() => {
            fetchData({
              pageIndex: table.getState().pagination.pageIndex,
              pageSize: table.getState().pagination.pageSize,
              filters: table.getState().columnFilters,
              sort: table.getState().sorting,
            })
          }}
        />
      )}
    </div>
  )
}
