"use client"

import * as React from "react"
import { useForm, useFieldArray } from "react-hook-form"
import * as z from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { DBName } from "../../utils/func"
import { useToast } from "../ui/use-toast"
import { Trash2 } from "lucide-react"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../ui/form"
import { Input } from "../ui/input"
import { Button } from "../ui/button"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select"
import { DataTable } from "../ui/data-table/data-table"
import { Checkbox } from "../ui/checkbox"
import { ColumnDef } from "@tanstack/react-table"

const formSchema = z.object({
  from: z.string().min(1, "From is required"),
  to: z.string().min(1, "To is required"),
  issuer: z.string().min(1, "Issuer is required"),
  receiver: z.string().min(1, "Receiver is required"),
  items: z.array(z.object({
    item: z.string().min(1, "Item is required"),
    weight: z.number().min(0, "Weight must be positive"),
  })),
})

interface CreateStoreTransactionProps {
  showCreate: boolean
  setShowCreate: (show: boolean) => void
  supabase: any
  callback: () => void
}

interface SelectOption {
  value: string
  label: string
}

export function CreateStoreTransaction({
  showCreate,
  setShowCreate,
  supabase,
  callback,
}: CreateStoreTransactionProps) {
  const { toast } = useToast()
  const [teams, setTeams] = React.useState<SelectOption[]>([])
  const [issuerUsers, setIssuerUsers] = React.useState<SelectOption[]>([])
  const [receiverUsers, setReceiverUsers] = React.useState<SelectOption[]>([])
  const [items, setItems] = React.useState<SelectOption[]>([])

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      items: [{ item: "", weight: 0 }],
    },
  })

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "items",
  })

  // Fetch teams and items on mount
  React.useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const [teamsData, itemsData] = await Promise.all([
          supabase.from(DBName.TEAMS).select("*"),
          supabase.from(DBName.ITEMS).select("*"),
        ])

        setTeams(teamsData.data?.map((team: any) => ({
          value: team.id.toString(),
          label: team.name,
        })) || [])

        setItems(itemsData.data?.map((item: any) => ({
          value: item.id.toString(),
          label: item.item_name,
        })) || [])
      } catch (error) {
        toast({
          title: "Error",
          description: "Failed to fetch initial data",
          variant: "destructive",
        })
      }
    }

    fetchInitialData()
  }, [supabase, toast])

  // Function to fetch users for a specific team
  const fetchUsersForTeam = async (teamId: string, type: "issuer" | "receiver") => {
    try {
      const { data, error } = await supabase
        .from(DBName.USERS)
        .select("*")
        .eq("team", parseInt(teamId))

      if (error) throw error

      const userOptions = data.map((user: any) => ({
        value: user.id.toString(),
        label: user.name,
      }))

      if (type === "issuer") {
        setIssuerUsers(userOptions)
      } else {
        setReceiverUsers(userOptions)
      }
    } catch (error: any) {
      toast({
        title: "Error",
        description: error.message || "Failed to fetch users",
        variant: "destructive",
      })
    }
  }

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      const baseTransaction = {
        from: parseInt(values.from),
        to: parseInt(values.to),
        issuer: parseInt(values.issuer),
        receiver: parseInt(values.receiver),
      }

      // Create a transaction for each item
      const { data, error } = await supabase
        .from(DBName.STORE_TRANSACTIONS)
        .insert(
          values.items.map(item => ({
            ...baseTransaction,
            item_id: parseInt(item.item),
            weight: item.weight,
          }))
        )
        .select()

      if (error) throw error

      toast({
        title: "Success",
        description: "Store transaction created successfully",
      })
      
      setShowCreate(false)
      callback()
    } catch (error: any) {
      toast({
        title: "Error",
        description: error.message,
        variant: "destructive",
      })
    }
  }

  return (
    <Dialog open={showCreate} onOpenChange={setShowCreate}>
      <DialogContent className="max-w-2xl">
        <DialogHeader>
          <DialogTitle>Create Store Transaction</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
            <div className="grid grid-cols-2 gap-4">
              <FormField
                control={form.control}
                name="from"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>From</FormLabel>
                    <Select 
                      value={field.value?.toString() || ""}
                      onValueChange={(value) => {
                        field.onChange(value)
                        form.setValue("issuer", "")
                        fetchUsersForTeam(value, "issuer")
                      }}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue>
                            {teams.find(team => team.value === field.value)?.label || "Select team"}
                          </SelectValue>
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {teams.map((team) => (
                          <SelectItem key={team.value} value={team.value}>
                            {team.label}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="to"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>To</FormLabel>
                    <Select 
                      value={field.value?.toString() || ""}
                      onValueChange={(value) => {
                        field.onChange(value)
                        form.setValue("receiver", "")
                        fetchUsersForTeam(value, "receiver")
                      }}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue>
                            {teams.find(team => team.value === field.value)?.label || "Select team"}
                          </SelectValue>
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {teams.map((team) => (
                          <SelectItem key={team.value} value={team.value}>
                            {team.label}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="issuer"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Issuer</FormLabel>
                    <Select 
                      value={field.value?.toString() || ""}
                      onValueChange={field.onChange}
                      disabled={!form.getValues("from")}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue>
                            {issuerUsers.find(user => user.value === field.value)?.label || "Select issuer"}
                          </SelectValue>
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {issuerUsers.map((user) => (
                          <SelectItem key={user.value} value={user.value}>
                            {user.label}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="receiver"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Receiver</FormLabel>
                    <Select 
                      value={field.value?.toString() || ""}
                      onValueChange={field.onChange}
                      disabled={!form.getValues("to")}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue>
                            {receiverUsers.find(user => user.value === field.value)?.label || "Select receiver"}
                          </SelectValue>
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {receiverUsers.map((user) => (
                          <SelectItem key={user.value} value={user.value}>
                            {user.label}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="space-y-4">
              {fields.map((field, index) => (
                <div key={field.id} className="flex gap-4">
                  <FormField
                    control={form.control}
                    name={`items.${index}.item`}
                    render={({ field }) => (
                      <FormItem className="flex-1">
                        <FormLabel>Item {index + 1}</FormLabel>
                        <Select 
                          value={field.value?.toString() || ""}
                          onValueChange={field.onChange}
                        >
                          <FormControl>
                            <SelectTrigger>
                              <SelectValue>
                                {items.find(item => item.value === field.value)?.label || "Select item"}
                              </SelectValue>
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            {items.map((item) => (
                              <SelectItem key={item.value} value={item.value}>
                                {item.label}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name={`items.${index}.weight`}
                    render={({ field }) => (
                      <FormItem className="flex-1">
                        <FormLabel>Weight</FormLabel>
                        <FormControl>
                          <Input 
                            type="number"
                            step="0.00001"
                            {...field}
                            onChange={(e) => field.onChange(parseFloat(e.target.value))}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <Button
                    type="button"
                    variant="outline"
                    size="icon"
                    className="mt-8"
                    onClick={() => remove(index)}
                  >
                    <Trash2 className="h-4 w-4" />
                  </Button>
                </div>
              ))}

              <Button
                type="button"
                variant="outline"
                onClick={() => append({ item: "", weight: 0 })}
              >
                Add Item
              </Button>
            </div>

            <div className="flex justify-end gap-2">
              <Button variant="outline" onClick={() => setShowCreate(false)}>
                Cancel
              </Button>
              <Button type="submit">Create</Button>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
