import { Sidebar } from './Sidebar';
import React, { useEffect } from 'react';
import { LSKeys } from '../utils/func';
import { useNavigate } from 'react-router-dom';

const Layout = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  
  useEffect(() => {
    const user = localStorage.getItem(LSKeys.USER);
    if (!user) {
      navigate("/login");
    }
  }, []) // eslint-disable-line

  return (
    <div className="flex h-screen">
      <div className="w-64 shrink-0 border-r">
        <Sidebar />
      </div>
      <main className="flex-1 overflow-auto">
        {children}
      </main>
    </div>
  );
};

export default Layout;