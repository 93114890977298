import { useParams } from "react-router-dom"

type Props = {}

const Report = (props: Props) => {
  const { reportId } = useParams()
  return (
    <div style={{height:"100%"}}>
      <iframe
        src={`https://app.hex.tech/2a61ea4a-09a5-42bd-a689-d045fa45b4c4/app/${reportId}/latest`}
        //@ts-ignore
        frameborder="0"
        width="100%"
        height="100%"
        allowtransparency
        title="report"
      ></iframe>
    </div>
  )
}

export default Report
