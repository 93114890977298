import { Box, Space, TextInput, Image } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";

import {
  AuthButton,
  AuthContainer,
  AuthForm,
  AuthFormHeader,
  AuthLabel,
  FormContainer,
  MainBackground,
} from "./auth.styles";
import ErrorMessage from "./common/ErrorMessage";
import { LSKeys } from "../utils/func";

const Login = ({supabase}:{supabase:any}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onSubmit = async (data:any) => {
    try {
        const phone = data.number;
        const password = data.password;
      setLoading(true);
      /**
       * get the user from the user database
       */
      const {data:sdata, error} = await supabase.from("users").select("*")
        .eq("phone_number", phone)
        .eq("password", password)
        .single();
        if (error) {
            setLoading(false);
            showNotification({
                title: "Error",
                message: error.message,
                color: "red",
            });

            }
        if(!sdata){
            setLoading(false);
            showNotification({
                title: "Error",
                message: "Invalid Credentials",
                color: "red",
            });
        }
        if (sdata) {
            console.log(sdata);
            localStorage.setItem(LSKeys.USER, JSON.stringify(sdata));
            showNotification({
                title: "Success",
                message: `Welcome ${sdata.name}!`,
                color: "blue",
            });
            navigate("/report/4a6256f1-b78b-4442-a532-817f1249a795");
        }
      
    } catch (error: any) {
      console.log(error);
      setLoading(false);
      showNotification({
        title: "Error",
        message: error,
        color: "red",
      });
    }
  };

  useEffect(() => {
    // if at is present, redirect to vendor_outwork
    const at = localStorage.getItem("at");
    if (at) {
      navigate("/report/65f7e817-12b1-409d-af76-db2d7e9dd39a");
    }
  }, []); // eslint-disable-line

  return (
    <MainBackground>
      <FormContainer>
        <Space h={32} />
        <Image
          maw={100}
          mx="auto"
          src="https://i.ibb.co/N9P4xgG/ausum-2.png"
          alt="Ausum Logo"
        />
        <Space h={12} />
        <AuthContainer>
          <AuthForm onSubmit={handleSubmit(onSubmit)}>
            <AuthFormHeader>
              {/* <img height={"40px"} src={ULogo} /> */}
              
            </AuthFormHeader>
            {/* <TextInput
            {...register("name", { required: "Name is required", minLength: { value: 3, message: "Name should be atleast 3 characters long" } })}
            name="name"
            
            type="text"
            label={<AuthLabel> Name </AuthLabel>}
          /> */}
            {/* Split name into first name and last name */}
            <Box
              
            >
              <TextInput
                {...register("number", {
                  required: "Number is required",
                })}
                name="number"
                
                placeholder="Phone Number"
                label={<AuthLabel> Phone Number </AuthLabel>}
              />
            </Box>
            {errors?.number?.message && (
              <ErrorMessage message={errors.number?.message as string} />
            )}
            <Space h={12} />

            <TextInput
              {...register("password", { required: "Password is required" })}
              name="password"
              placeholder="Create a password"
              label={<AuthLabel> Password </AuthLabel>}
              type="password"
            />
            {errors?.password?.message && (
              <ErrorMessage message={errors.password?.message as string} />
            )}
            <Space h={12} />
            {/* @ts-ignore */}
            <AuthButton
              loading={loading}
              disable={!loading}
              fullWidth
              type="submit"
            >
              {" "}
              Login{" "}
            </AuthButton>
            <Space h={16} />
          </AuthForm>
        </AuthContainer>
        <Space h={32} />
      </FormContainer>
      {/* <Box>
        <img style={{ height: "100%", width: "100%" }} src={AuthHero} />
      </Box> */}
    </MainBackground>
  );
};

export default Login;
