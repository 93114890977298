import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom"
import ExtTransaction from "./resource/ExtTransaction"
import StoreTransaction from "./resource/StoreTransaction"
import VendorOutwork from "./resource/VendorOutwork"
import Items from "./resource/Items"
import React from "react"
import Layout from "./components/Layout"
import Users from "./resource/User"
import Teams from "./resource/Team"
import OverdueOrders from "./resource/OverdueOrders"
import Report from "./resource/Report"
import Login from "./components/Login"
import TeamwiseProduct from "./resource/TeamwiseProduct"
import KarigarProduct from "./resource/KarigarProduct"
import Products from "./resource/Products"

function App({ supabase }:{supabase:any}) {
  return (
    <Router>
      <Routes>
      < Route
          path="/login"
          element={
            <Login supabase={supabase} />
          }
        />
        <Route
          path="/external_transaction"
          element={
            <Layout>
              <ExtTransaction supabase={supabase} />
            </Layout>
          }
        />
        <Route
          path="/store_transaction"
          element={
            <Layout>
              <StoreTransaction supabase={supabase} />
            </Layout>
          }
        />
        <Route
          path="/vendor_outwork"
          element={
            <Layout>
              <VendorOutworkContainer supabase={supabase} />
            </Layout>
          }
        />
        <Route
          path="/items"
          element={
            <Layout>
              <Items supabase={supabase} />
            </Layout>
          }
        />
        <Route
          path="/products"
          element={
            <Layout>
              <Products supabase={supabase} />
            </Layout>
          }
        />
        <Route path="/" element={<Navigate to="/report/65f7e817-12b1-409d-af76-db2d7e9dd39a" />} />
        <Route
          path="/users"
          element={
            <Layout>
              <Users supabase={supabase} />
            </Layout>
          }
        />
        <Route
          path="/teams"
          element={
            <Layout>
              <Teams supabase={supabase} />
            </Layout>
          }
        />
        <Route
          path="/overdue-orders"
          element={
            <Layout>
              <OverdueOrders supabase={supabase} />
            </Layout>
          }
        />
        <Route
          path="/report/:reportId"
          element={
            <Layout>
              <Report />
            </Layout>
          }
        />
      </Routes>
    </Router>
  )
}
// New container component to handle tab routing
function VendorOutworkContainer({ supabase }: { supabase: any }) {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const tab = searchParams.get('tab')

  switch (tab) {
    case 'vendor_outwork':
      return <VendorOutwork supabase={supabase} />
    case 'karigar':
      return (
        <KarigarProduct
          supabase={supabase}
          otherProps={{
            karigar_name: "",
            "Loaded Date": new Date().toISOString(),
            Product: ""
          }}
          selectedRows={[]}
          setSelectedRows={() => {}}
          refresh={false}
        />
      )
    case 'teamwise':
      return (
        <TeamwiseProduct
          supabase={supabase}
          otherProps={{}}
          selectedRows={[]}
          setSelectedRows={() => {}}
          refresh={false}
          onMultiRowSelectClick={() => {}}
        />
      )
    default:
      return <VendorOutwork supabase={supabase} />
  }
}
export default App
