import { useState } from "react"
import Table from "../components/Table"
import { VOView } from "../utils/func"
import Papa from "papaparse"
import CreateTeam from "../modal/CreateTeam"

type Props = {
  supabase: any
}

const OverdueOrders = ({ supabase }: Props) => {
  const fetcherFunction = async ({
    pageIndex,
    pageSize,
    filters,
    sort,
  }: any) => {
    try {
      let query = supabase
        .from(VOView.OVERDUE_ORDERS)
        .select("*", { count: "exact" })
        .range(pageIndex * pageSize, (pageIndex + 1) * pageSize - 1)

        if (filters && filters.length > 0) {
          filters.forEach((filter: { id: any; value: any }, index: number) => {
            
              query = query.ilike(filter.id, `%${filter.value}%`)
            
          })
        }

      if (sort && sort.length > 0) {
        //@ts-ignore
        sort.forEach((sortObj) => {
          query = query.order(sortObj.id, { ascending: !sortObj.desc })
        })
      }

      const { data, count } = await query
      data.forEach((d: any) => {
        const date = new Date(d.created_at)
        d.created_at = date.toLocaleString()
      })
      return { data, count }
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  const [showCreate, setShowCreate] = useState(false)

  function convertToCSV(json: any) {
    const csv = Papa.unparse(json)
    return csv
  }

  const downloadCSV = async () => {
    const data = await supabase.from(VOView.OVERDUE_ORDERS).select("*")
    const csv = convertToCSV(data)
    const blob = new Blob([csv], { type: "text/csv" })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement("a")
    a.href = url
    a.download = `${VOView.OVERDUE_ORDERS}.csv`
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  const onCreate = async () => {
    setShowCreate(true)
  }

  return (
    <>
      <Table
        CreateComponent={CreateTeam}
        showCreate={showCreate}
        setShowCreate={setShowCreate}
        handleDownload={downloadCSV}
        fetcher={fetcherFunction}
        onCreate={onCreate}
        supabase={supabase}
        showCreateButton={false}
        getRowColor={(row: any) => {
          // console.log(row)
          let color;
          if (row['pendency_days'] > 300) {
            color = "rgb(255, 241, 243)"
          }
          if(row['pendency_days'] > 150 && row['pendency_days'] < 300){
            color = "rgb(254, 247, 195)"
          }
          return color
        }}
      />
    </>
  )
}

export default OverdueOrders
