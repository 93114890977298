import {
  Button,
  Modal,
  Space,
  Text,
  TextInput,
} from "@mantine/core"
import { showNotification } from "@mantine/notifications"
import React from "react"
import { useForm } from "react-hook-form"
import { DBName } from "../utils/func"

type Props = {
  showCreate: boolean
  setShowCreate: (opened: boolean) => void
  supabase: any
  callback: any
  isEdit: boolean
  payload: any
}

const styles = {
  content: {
    height: "400px",
  },
}

const CreateTeam = ({
  showCreate,
  setShowCreate,
  supabase,
  callback,
  isEdit = false,
  payload,
}: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: payload?.name || "",
    },
  })

  const onSubmit = async (formData: any) => {
    try {
      // get some random number beteen 1000 - 5000
      if (isEdit) {
        let {  error } = await supabase
          .from(DBName.TEAMS)
          .update(formData)
          .eq("id", payload.id)
        if (error) {
          showNotification({
            title: "Error",
            message: error.message || "Error updating Team",
            color: "red",
          })
          return
        }
        callback()
        showNotification({
          title: "Success",
          message: "Team updated successfully",
          color: "yellow",
        })
        return
      }
      const id = Math.floor(Math.random() * 5000) + 1000
      formData.id = id
      await supabase.from(DBName.TEAMS).insert([formData])
      callback()
      showNotification({
        title: "Success",
        message: "Team created successfully",
        color: "yellow",
      })
    } catch (error: any) {
      console.log(error)
      showNotification({
        title: "Error",
        message: error.message || "Error creating Team",
        color: "red",
      })
    }
  }

  const handleDelete = async (id:any) => {
    try {
      let {  error } = await supabase.from(DBName.TEAMS).delete().eq("id", id)
      if (error) {
        showNotification({
          title: "Error",
          message: error.message || "Error deleting Team",
          color: "red",
        })
        return
      }
      callback()
      showNotification({
        title: "Success",
        message: "Team deleted successfully",
        color: "yellow",
      })
    } catch (error: any) {
      console.log(error)
      showNotification({
        title: "Error",
        message: error.message || "Error deleting Team",
        color: "red",
      })
    }
  }

  return (
    <Modal
      title={<Text size="xl">{isEdit ? "Edit Team" : "Create Team"}</Text> }
      styles={styles}
      opened={showCreate}
      onClose={() => setShowCreate(false)}
    >
      <TextInput label="Name" {...register("name", { required: true })} />
      {errors.name && <span>This field is required</span>}

      <Space h="md" />
      <Button className="primary" fullWidth onClick={handleSubmit(onSubmit)}>
        {isEdit ? "Update" : "Create"}
      </Button>
      <Space h="md" />
      {
        isEdit && (
          <Button color="red" fullWidth onClick={() => handleDelete(payload.id)}>
            Delete
          </Button>
        )
      }
    </Modal>
    // <div>Modal</div>
  )
}

export default CreateTeam
