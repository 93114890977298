import * as React from "react"
import { useForm, Controller, useFieldArray } from "react-hook-form"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog"
import { Input } from "../ui/input"
import { Button } from "../ui/button"
import { Label } from "../ui/label"
import { DBName } from "../../utils/func"
import { useToast } from "../ui/use-toast"
import { ScrollArea } from "../ui/scroll-area"
import { Plus, Trash } from "lucide-react"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select"

interface CreateProductProps {
  showCreate: boolean
  setShowCreate: (show: boolean) => void
  supabase: any
  callback?: () => void
}

interface FormValues {
  product_id: string
  image: string
  items: Array<{
    item_id: string
    quantity: number
  }>
}

export function CreateProduct({ showCreate, setShowCreate, supabase, callback }: CreateProductProps) {
  const { toast } = useToast()
  const [items, setItems] = React.useState<Array<{ value: string; label: string }>>([])
  
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<FormValues>({
    defaultValues: {
      items: [{ item_id: "", quantity: 1 }]
    }
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: "items"
  })

  React.useEffect(() => {
    fetchItems()
  }, [])

  const fetchItems = async () => {
    try {
      const { data: itemsData, error } = await supabase
        .from(DBName.ITEMS)
        .select("id, item_name")

      if (!error) {
        setItems(itemsData.map((item: any) => ({
          value: item.id.toString(),
          label: item.item_name,
        })))
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to fetch items",
        variant: "destructive",
      })
    }
  }

  const uploadImage = async (file: File) => {
    try {
      console.log('Starting image upload for file:', file.name)
      
      const filePath = `Product Images/${file.name}`
      console.log('Generated file path:', filePath)
      
      const { data, error } = await supabase.storage
        .from('Product Images')
        .upload(filePath, file)

      if (error) {
        console.error('Upload error:', error)
        throw error
      }
      
      console.log('File uploaded successfully:', data)

      const { data: { publicUrl } } = supabase.storage
        .from('Product Images')
        .getPublicUrl(filePath)
      
      console.log('Generated public URL:', publicUrl)

      if (!publicUrl) {
        throw new Error('Failed to generate public URL')
      }

      return publicUrl
    } catch (error) {
      console.error('Error in uploadImage function:', error)
      throw error
    }
  }

  const onSubmit = async (formData: FormValues) => {
    try {
      const fileInput = document.getElementById('image') as HTMLInputElement
      const file = fileInput?.files?.[0]
      let imageUrl = ''

      if (file) {
        imageUrl = await uploadImage(file)
      }

      // First insert the product
      const { data: product, error: productError } = await supabase
        .from('products')
        .insert([{
          product_id: formData.product_id,
          image: imageUrl
        }])
        .select()
        .single()

      if (productError) throw productError

      // Then insert the item mappings
      const mappings = formData.items.map(item => ({
        product_id: formData.product_id,
        item_id: parseInt(item.item_id),
        quantity: item.quantity
      }))

      const { error: mappingError } = await supabase
        .from('product_items_mapping')
        .insert(mappings)

      if (mappingError) throw mappingError

      toast({
        title: "Success",
        description: "Product created successfully",
      })

      reset()
      callback?.()
      setShowCreate(false)
    } catch (error: any) {
      toast({
        title: "Error",
        description: error.message || "Failed to create product",
        variant: "destructive",
      })
    }
  }

  return (
    <Dialog open={showCreate} onOpenChange={setShowCreate}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Create New Product</DialogTitle>
        </DialogHeader>
        <ScrollArea className="max-h-[80vh]">
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 p-4">
            <div className="space-y-2">
              <Label htmlFor="product_id">Product ID</Label>
              <Input
                id="product_id"
                {...register("product_id", { required: "Product ID is required" })}
              />
              {errors.product_id && (
                <span className="text-sm text-destructive">
                  {errors.product_id.message}
                </span>
              )}
            </div>

            <div className="space-y-2">
              <Label htmlFor="image">Image</Label>
              <Input
                id="image"
                type="file"
                {...register("image")}
                accept="image/*"
              />
            </div>

            <div className="space-y-4">
              <Label>Items</Label>
              {fields.map((field: { id: React.Key | null | undefined }, index: any) => (
                <div key={field.id} className="flex gap-4 items-end">
                  <div className="flex-1 space-y-2">
                    <Controller
                      name={`items.${index}.item_id`}
                      control={control}
                      rules={{ required: "Item is required" }}
                      render={({ field }) => (
                        <Select
                          value={field.value}
                          onValueChange={field.onChange}
                        >
                          <SelectTrigger>
                            <SelectValue placeholder="Select item" />
                          </SelectTrigger>
                          <SelectContent>
                            {items.map((item) => (
                              <SelectItem key={item.value} value={item.value}>
                                {item.label}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      )}
                    />
                  </div>
                  
                  <div className="w-24 space-y-2">
                    <Input
                      type="number"
                      {...register(`items.${index}.quantity`, {
                        required: "Required",
                        min: { value: 1, message: "Min 1" }
                      })}
                      placeholder="Qty"
                    />
                  </div>

                  <Button
                    type="button"
                    variant="ghost"
                    size="icon"
                    onClick={() => remove(index)}
                  >
                    <Trash className="h-4 w-4" />
                  </Button>
                </div>
              ))}

              <Button
                type="button"
                variant="outline"
                size="sm"
                onClick={() => append({ item_id: "", quantity: 1 })}
                className="w-full"
              >
                <Plus className="h-4 w-4 mr-2" />
                Add Item
              </Button>
            </div>

            <Button type="submit" className="w-full">
              Create Product
            </Button>
          </form>
        </ScrollArea>
      </DialogContent>
    </Dialog>
  )
}
