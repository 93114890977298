import { useState } from "react"
import Table from "../components/Table"
import { DBName } from "../utils/func"
import Papa from "papaparse"
import CreateTeam from "../modal/CreateTeam"
import { showNotification } from "@mantine/notifications"

type Props = {
  supabase: any
  otherProps: any
  selectedRows: any
  setSelectedRows: any
  refresh: any
  onMultiRowSelectClick: any
}

const TeamwiseProduct = ({
  supabase,
  otherProps,
  selectedRows,
  setSelectedRows,
  refresh,
  onMultiRowSelectClick
}: Props) => {
  const fetcherFunction = async ({ pageIndex, pageSize, filters, sort }:any) => {
    try {

      // let query = supabase
      // .from(DBName.VENDOR_OUTWORK)
      // .select(`
      //   Status,
      //   Product,
      //   "Group Id"
      // `)
      // .not('Status', 'eq', null)
      // .order('Product')
      //   // .order("created_at", { ascending: false })
      //   .range(pageIndex * pageSize, (pageIndex + 1) * pageSize - 1)
      let query = supabase
        .from(DBName.VENDOR_OUTWORK)
        .select(
          `
            "Batch Number",
            "Product",
            "Loaded Date",
            "Status",
            "Group Id"
          `,
          { count: "exact" }
        )
        .is("Wabill No", null)
        .filter("Product", "eq", otherProps.Product)
        .filter("Group Id", "eq", otherProps['Group Id'])
        .filter("Status", "eq", otherProps["Status"])
        // .order("created_at", { ascending: false })
        .range(pageIndex * pageSize, (pageIndex + 1) * pageSize - 1)
        if (filters && filters.length > 0) {
          filters.forEach((filter: { id: any; value: any }, index: number) => {
            
              query = query.ilike(filter.id, `%${filter.value}%`)
            
          })
        }
      if (sort && sort.length > 0) {
        sort.forEach((sortObj:any) => {
          query = query.order(sortObj.id, { ascending: !sortObj.desc })
        })
      }

      const { data, count } = await query
      data.forEach((d:any) => {
        const date = new Date(d.created_at)
        const loadedDate = new Date(d['Loaded Date'])
        const targetDate = new Date(d['Target Date'])
        d.created_at = date.toLocaleString()
        d.LoadedDate = loadedDate.toLocaleString()
        d.TargetDate = targetDate.toLocaleString()
        d.Theme = d.Product.substring(2, 9)
      })
      const groupedData = data.reduce((acc:any, item:any) => {
        const key = `${item.Status}-${item.Product}-${item['Group Id']}`;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(item);
        return acc;
      }, {});
      // convert object to array
      const thisKey:string = otherProps['Status'] + '-' + otherProps['Product'] + '-' + otherProps['Group Id']
      const groupedDataArray:any = []
      groupedData[thisKey].forEach((item:any) => {
        groupedDataArray.push(item)
      })

      return { data:groupedDataArray, count }
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  const [showCreate, setShowCreate] = useState(false)

  function convertToCSV(json:any) {
    const csv = Papa.unparse(json)
    return csv
  }

  const downloadCSV = async () => {
    const data = await supabase.from(DBName.TEAMS).select("*")
    const csv = convertToCSV(data)
    const blob = new Blob([csv], { type: "text/csv" })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement("a")
    a.href = url
    a.download = `${DBName.TEAMS}.csv`
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  const onCreate = async () => {
    setShowCreate(true)
  }

  const onMutiSelectClick = async () => {
    if(selectedRows.length === 0) {
      showNotification({
        title: "Error",
        message: "Please select atleast one row",
        color: "red",
      })
      return
    }
    onMultiRowSelectClick(selectedRows)
  }

  return (
    <>
      <Table
        CreateComponent={CreateTeam}
        showCreate={showCreate}
        setShowCreate={setShowCreate}
        handleDownload={downloadCSV}
        fetcher={fetcherFunction}
        onCreate={onCreate}
        supabase={supabase}
        multiRowSelection={true}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        rowId={"Batch Number"}
        refresh={refresh}
        showOnMultiSelect={true}
        onMutiSelectClick={onMutiSelectClick}
      />
    </>
  )
}

export default TeamwiseProduct
