"use client"

import * as React from "react"
import { useForm } from "react-hook-form"
import * as z from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { DBName } from "../../utils/func"
import { useToast } from "../ui/use-toast"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../ui/form"
import { Input } from "../ui/input"
import { Button } from "../ui/button"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select"

const formSchema = z.object({
  source: z.string().min(1, "Source is required"),
  type_of_transaction: z.string().min(1, "Transaction type is required"),
  dvr_number: z.string().optional(),
  dc_number: z.string().optional(),
  purity: z.string().min(1, "Purity is required"),
  weight: z.number().min(0, "Weight must be positive"),
  quantity: z.number().min(0, "Quantity must be positive"),
  type_of_material: z.string().min(1, "Material type is required"),
  packed_or_opened_by: z.string().min(1, "Packed/Opened by is required"),
  processed_by: z.string().min(1, "Processed by is required"),
})

const sources = [
  { value: "Tanishq", label: "Tanishq" },
  { value: "Vasantham Creation", label: "Vasantham Creation" },
  { value: "Others", label: "Others" },
]

interface CreateExtTransactionProps {
  showCreate: boolean
  setShowCreate: (show: boolean) => void
  supabase: any
  callback: () => void
}

export function CreateExtTransaction({
  showCreate,
  setShowCreate,
  supabase,
  callback,
}: CreateExtTransactionProps) {
  const { toast } = useToast()
  const [users, setUsers] = React.useState<any[]>([])
  const [items, setItems] = React.useState<any[]>([])

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      source: "",
      type_of_transaction: "",
      purity: "916",
      weight: 0,
      quantity: 0,
    },
  })

  const type_of_transaction = form.watch("type_of_transaction")
  const isTransactionInward = type_of_transaction === "Inward"

  React.useEffect(() => {
    fetchAllUsers()
    fetchAllItems()
  }, [])

  const fetchAllUsers = async () => {
    try {
      const { data } = await supabase.from(DBName.USERS).select("*")
      const userOptions = data.map((user: any) => ({
        value: user.id,
        label: user.name,
      }))
      setUsers(userOptions)
    } catch (error: any) {
      toast({
        title: "Error",
        description: error.message || "Error fetching users",
        variant: "destructive",
      })
    }
  }

  const fetchAllItems = async () => {
    try {
      const { data } = await supabase.from(DBName.ITEMS).select("*")
      const itemOptions = data.map((item: any) => ({
        value: item.id,
        label: item.item_name,
      }))
      setItems(itemOptions)
    } catch (error: any) {
      toast({
        title: "Error",
        description: error.message || "Error fetching items",
        variant: "destructive",
      })
    }
  }

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      const { error } = await supabase
        .from(DBName.EXT_TRANSACTIONS)
        .insert({
          source: values.source,
          type_of_transaction: values.type_of_transaction,
          dvr_number: values.dvr_number,
          dc_number: values.dc_number,
          purity: values.purity,
          weight: values.weight,
          quantity: values.quantity,
          type_of_material: values.type_of_material,
          packed_or_opened_by: values.packed_or_opened_by,
          processed_by: values.processed_by,
        })

      if (error) throw error

      toast({
        title: "Success",
        description: "External transaction created successfully",
      })
      setShowCreate(false)
      callback()
    } catch (error: any) {
      toast({
        title: "Error",
        description: error.message || "Error creating external transaction",
        variant: "destructive",
      })
    }
  }

  return (
    <Dialog open={showCreate} onOpenChange={setShowCreate}>
      <DialogContent className="max-w-2xl max-h-[90vh] overflow-y-auto">
        <DialogHeader>
          <DialogTitle>Create External Transaction</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <div className="grid grid-cols-2 gap-4">
              <FormField
                control={form.control}
                name="source"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Source</FormLabel>
                    <Select onValueChange={field.onChange} defaultValue={field.value}>
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Select source" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {sources.map((source) => (
                          <SelectItem key={source.value} value={source.value}>
                            {source.label}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="type_of_transaction"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Transaction Type</FormLabel>
                    <Select onValueChange={field.onChange} defaultValue={field.value}>
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Select type" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value="Inward">Inward</SelectItem>
                        <SelectItem value="Outward">Outward</SelectItem>
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />

              {isTransactionInward ? (
                <FormField
                  control={form.control}
                  name="dvr_number"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>DVR Number</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              ) : (
                <FormField
                  control={form.control}
                  name="dc_number"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>DC Number</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              )}

              <FormField
                control={form.control}
                name="purity"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Purity</FormLabel>
                    <Select onValueChange={field.onChange} defaultValue={field.value}>
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Select purity" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value="916">916</SelectItem>
                        <SelectItem value="917">917</SelectItem>
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="weight"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Weight</FormLabel>
                    <FormControl>
                      <Input 
                        type="number" 
                        step="0.00001"
                        {...field}
                        onChange={(e) => field.onChange(parseFloat(e.target.value))}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="quantity"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Quantity</FormLabel>
                    <FormControl>
                      <Input 
                        type="number"
                        step="0.00001"
                        {...field}
                        onChange={(e) => field.onChange(parseFloat(e.target.value))}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="type_of_material"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Material Type</FormLabel>
                    <Select 
                      value={field.value?.toString() || ""}
                      onValueChange={(value) => field.onChange(value)}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Select material">
                            {items.find(item => item.value.toString() === field.value?.toString())?.label}
                          </SelectValue>
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {items.map((item) => (
                          <SelectItem key={item.value} value={item.value.toString()}>
                            {item.label}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />

<FormField
  control={form.control}
  name="packed_or_opened_by"
  render={({ field }) => (
    <FormItem>
      <FormLabel>Packed/Opened By</FormLabel>
      <Select 
        value={field.value?.toString() || ""}
        onValueChange={(value) => field.onChange(value)}
      >
        <FormControl>
          <SelectTrigger>
            <SelectValue placeholder="Select user">
              {users.find(user => user.value.toString() === field.value?.toString())?.label}
            </SelectValue>
          </SelectTrigger>
        </FormControl>
        <SelectContent>
          {users.map((user) => (
            <SelectItem key={user.value} value={user.value.toString()}>
              {user.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      <FormMessage />
    </FormItem>
  )}
/>

<FormField
  control={form.control}
  name="processed_by"
  render={({ field }) => (
    <FormItem>
      <FormLabel>Processed By</FormLabel>
      <Select 
        value={field.value?.toString() || ""}
        onValueChange={(value) => field.onChange(value)}
      >
        <FormControl>
          <SelectTrigger>
            <SelectValue placeholder="Select user">
              {users.find(user => user.value.toString() === field.value?.toString())?.label}
            </SelectValue>
          </SelectTrigger>
        </FormControl>
        <SelectContent>
          {users.map((user) => (
            <SelectItem key={user.value} value={user.value.toString()}>
              {user.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      <FormMessage />
    </FormItem>
  )}
/>
            </div>
            <div className="flex justify-end gap-2">
              <Button variant="outline" onClick={() => setShowCreate(false)}>
                Cancel
              </Button>
              <Button type="submit">Create</Button>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
