export const generateColumns = (data: any[]) => {
  if (!data || data.length === 0) return []
  
  return Object.keys(data[0]).map((key) => ({
    accessorKey: key,
    header: key,
  }))
}

export const getFilterVariant = (accessorKey: string) => {
  if (accessorKey === 'created_at') return 'date-range'
  return 'text'
}

export enum DBName {
  USERS = 'users',
  ITEMS = 'Items',
  TEAMS = 'teams',
  STORE_TRANSACTIONS = 'store_transactions',
  EXT_TRANSACTIONS = 'external_transactions',
  VENDOR_OUTWORK = 'vendor_outwork',
  VENDOR_OUTWORK_LOG = 'vendor_outwork_log',
  OVERDUE_ORDERS = 'overdue_orders'
}

export enum VOView {
  OVERDUE_ORDERS = 'overdue_orders',
  KARIGAR = 'karigar',
  TEAM_VIEW = 'team_view'
}

export enum LSKeys {
  USER = 'user'
}