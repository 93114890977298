"use client"

import { Table } from "@tanstack/react-table"
import { Button } from "../button"
import { DataTableViewOptions } from "./data-table-view-options"
import { DataTableCustomFilter } from "./data-table-custom-filter"

interface DataTableToolbarProps<TData> {
  table: Table<TData>
  showCreateButton?: boolean
  setShowCreate?: (show: boolean) => void
  handleDownload?: () => void
  additionalButtons?: (table: Table<TData>) => React.ReactNode
}

export function DataTableToolbar<TData>({
  table,
  showCreateButton,
  setShowCreate,
  handleDownload,
  additionalButtons,
}: DataTableToolbarProps<TData>) {
  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-2">
          <DataTableCustomFilter table={table} />
          <DataTableViewOptions table={table} />
        </div>
        <div className="flex items-center space-x-2">
          {additionalButtons && additionalButtons(table)}
          {handleDownload && (
            <Button
              variant="outline"
              className="h-8"
              onClick={handleDownload}
            >
              Download
            </Button>
          )}
          {showCreateButton && (
            <Button
              variant="default"
              className="h-8"
              onClick={() => setShowCreate?.(true)}
            >
              Create
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}
