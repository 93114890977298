import { Drawer, Space } from "@mantine/core"
import { Input, Text } from "@mantine/core"
import React from "react"

type Props = {
  opened: boolean
  close: () => void
  data: any
}

const ADrawer = ({ opened, close, data }: Props) => {
  return (
    <Drawer
      position="right"
      opened={opened}
      onClose={close}
      title="Details"
    >
      <div>
        {Object.entries(data).map(([key, value]) => (
          <div key={key}>
            <Text>{key}</Text>
            {/* @ts-ignore */}
            <Input disabled value={value} />
            <Space h="md" />
          </div>
        ))}
      </div>
    </Drawer>
  )
}

export default ADrawer
