import { useState } from "react"
import { DataTable } from "../components/ui/data-table/data-table"
import { CreateExtTransaction } from "../components/modals/create-ext-transaction"
import Papa from "papaparse"
import { ColumnDef } from "@tanstack/react-table"
import { Button } from "../components/ui/button"
import { ArrowUpDown } from "lucide-react"
import { Checkbox } from "../components/ui/checkbox"
import { DBName } from "../utils/func"

interface ExtTransaction {
  created_at: string
  source: string
  dvr_number?: string
  dc_number?: string
  purity: string
  weight: number
  quantity: number
  type_of_transaction: string
  packed_or_opened_by: { name: string }
  processed_by: { name: string }
  type_of_material: { item_name: string }
}

type Props = {
  supabase: any
}

const ExtTransaction = ({ supabase }: Props) => {
  const [showCreate, setShowCreate] = useState(false)

  const columns: ColumnDef<ExtTransaction>[] = [
    {
      id: "select",
      header: ({ table }) => (
        <Checkbox
          checked={table.getIsAllPageRowsSelected()}
          onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
          aria-label="Select all"
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          checked={row.getIsSelected()}
          onCheckedChange={(value) => row.toggleSelected(!!value)}
          aria-label="Select row"
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "created_at",
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Created At
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      ),
      enableColumnFilter: true,
    },
    {
      accessorKey: "source",
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Source
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      ),
      enableColumnFilter: true,
    },
    {
      accessorKey: "type_of_transaction",
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Transaction Type
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      ),
      enableColumnFilter: true,
    },
    {
      accessorKey: "dvr_number",
      header: "DVR Number",
      enableColumnFilter: true,
    },
    {
      accessorKey: "dc_number",
      header: "DC Number",
      enableColumnFilter: true,
    },
    {
      accessorKey: "purity",
      header: "Purity",
      enableColumnFilter: true,
    },
    {
      accessorKey: "weight",
      header: "Weight",
      enableColumnFilter: true,
    },
    {
      accessorKey: "quantity",
      header: "Quantity",
      enableColumnFilter: true,
    },
    {
      accessorKey: "packed_or_opened_by.name",
      header: "Packed/Opened By",
      enableColumnFilter: true,
    },
    {
      accessorKey: "processed_by.name",
      header: "Processed By",
      enableColumnFilter: true,
    },
    {
      accessorKey: "type_of_material.item_name",
      header: "Material Type",
      enableColumnFilter: true,
    },
  ]

  const fetcherFunction = async ({ pageIndex, pageSize, filters, sort }: any) => {
    try {
      let query = supabase
        .from(DBName.EXT_TRANSACTIONS)
        .select(`
          created_at,
          source,
          dvr_number,
          dc_number,
          purity,
          weight,
          quantity,
          type_of_transaction,
          packed_or_opened_by:users!public_external_transactions_packed_or_opened_by_fkey (name),
          processed_by:users!public_external_transactions_processed_by_fkey (name),
          type_of_material:Items (item_name)
        `, { count: "exact" })
        .range(pageIndex * pageSize, (pageIndex + 1) * pageSize - 1)

      if (filters && filters.length > 0) {
        filters.forEach((filter: { id: any; value: any }) => {
          if (!filter.id || !filter.value) return
          query = query.ilike(filter.id, `%${filter.value}%`)
        })
      }

      if (sort && sort.length > 0) {
        sort.forEach((sortObj: any) => {
          query = query.order(sortObj.id, { ascending: !sortObj.desc })
        })
      }

      const { data, count } = await query

      if (!data) return { data: [], count: 0 }

      const formattedData = data.map((d: any) => ({
        ...d,
        created_at: new Date(d.created_at).toLocaleString(),
      }))

      return { data: formattedData, count: count || 0 }
    } catch (error) {
      console.error("Error fetching data:", error)
      return { data: [], count: 0 }
    }
  }

  const downloadCSV = async (table: any) => {
    try {
      const { data } = await supabase.from(DBName.EXT_TRANSACTIONS).select("*")
      const csv = Papa.unparse(data)
      const blob = new Blob([csv], { type: "text/csv" })
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement("a")
      a.href = url
      a.download = `ExtTransactions_${new Date().toISOString()}.csv`
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      window.URL.revokeObjectURL(url)
    } catch (error) {
      console.error("Error downloading CSV:", error)
    }
  }

  return (
    <div className="container mx-auto py-10">
      <DataTable<ExtTransaction>
        columns={columns}
        fetcher={fetcherFunction}
        onCreate={() => setShowCreate(true)}
        handleDownload={downloadCSV}
        CreateComponent={CreateExtTransaction}
        showCreate={showCreate}
        setShowCreate={setShowCreate}
        supabase={supabase}
      />
    </div>
  )
}

export default ExtTransaction