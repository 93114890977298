"use client"

import * as React from "react"
import { format } from "date-fns"
import { Calendar as CalendarIcon } from "lucide-react"
import { DateRange } from "react-day-picker"
import { cn } from "../../lib/utils"
import { Button } from "./button"
import { Calendar } from "./calendar"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "./popover"

interface DatePickerWithRangeProps {
  date: DateRange | undefined
  setDate: (date: DateRange | undefined) => void
  onApply?: (range: DateRange) => void
  placeholder?: string
}

export function DatePickerWithRange({
  date,
  setDate,
  onApply,
  placeholder = "Pick a date range"
}: DatePickerWithRangeProps) {
  const [isOpen, setIsOpen] = React.useState(false)
  const [tempRange, setTempRange] = React.useState<DateRange | undefined>(date)

  React.useEffect(() => {
    setTempRange(date)
  }, [date])

  const handleSelect = (range: DateRange | undefined) => {
    setTempRange(range)
  }

  const handleApply = () => {
    if (tempRange?.from && tempRange?.to) {
      setDate(tempRange)
      onApply?.(tempRange)
      setIsOpen(false)
    }
  }

  const handleCancel = () => {
    setTempRange(date)
    setIsOpen(false)
  }

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          className={cn(
            "w-[300px] justify-start text-left font-normal",
            !date && "text-muted-foreground"
          )}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {date?.from ? (
            date.to ? (
              <>
                {format(date.from, "MMM dd, yyyy")} -{" "}
                {format(date.to, "MMM dd, yyyy")}
              </>
            ) : (
              format(date.from, "MMM dd, yyyy")
            )
          ) : (
            <span>{placeholder}</span>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent 
        className="w-auto p-0" 
        align="start"
      >
        <div className="p-3 bg-white border rounded-md shadow-md">
          <Calendar
            initialFocus
            mode="range"
            defaultMonth={tempRange?.from || new Date()}
            selected={tempRange}
            onSelect={handleSelect}
            numberOfMonths={2}
            className="bg-white"
          />
          <div className="mt-4 flex justify-end gap-2 border-t pt-4">
            <Button
              variant="outline"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button 
              className="bg-primary text-white hover:bg-primary/90"
              onClick={handleApply}
              disabled={!tempRange?.from || !tempRange?.to}
            >
              Apply
            </Button>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  )
}
